import axios from "axios";
import {
  ParkingLot,
  ParkingLotBasic,
  ParkingLotsList,
  ParkingLotCreate,
  ParkingLotNameCheck,
  ParkingLotUpdate,
  ParkingLotStats,
  ParkingLotOccupancy,
  ParkingLotSavedDetails,
  ParkingLotZoneOccupancyCount,
  ParkingLotDwellTime,
  ParkingLotTurnover,
  ParkingLotAppUsageCount,
  ParkingLotTenantOccupancy,
  ParkingLotUploadedImagePaths,
  ParkingLotUntrackedInfoOnly,
  ParkingLotMapStats,
  ParkingLotHistory,
  ParkingSpot,
  ParkingSpotUpdate,
  ParkingSpotInaccuracy,
  ParkingZone,
  ParkingZoneUpdate,
  ParkingZoneUntrackedInfoOnlyUpdate,
  SpecialAreaUpdate,
  SpecialArea,
  ParkingLane,
  ParkingLaneUpdate,
  ParkingLotNvrDetailsCreate,
  Camera,
  CameraCreate,
  CameraUpdate,
  CameraMapDetails,
  CameraDetails,
  CamerasList,
  Organization,
  OrganizationUserLoginPayload,
  OrganizationUserLoginResponseToken,
  OrganizationUser,
  OrganizationCreate,
  OrganizationUpdate,
  OrganizationUserCreate,
  OrganizationUserUpdate,
  OrganizationUserType,
  OrganizationUserAuthorization,
  UserActivityAuditLog,
  OrganizationUserWithExtraData,
  MaintenanceWindow,
  Alert,
  AlertsPaginated,
  GenerateAlert,
  CheckEscalatedAlert,
  PaginatedItems,
  ParkingZoneUntrackedInfoOnly,
  Server,
  Message,
  MessageCreate,
  MessageUpdate,
  MessageTemplate,
  NmDatasetSaveResult,
  AlertTypes,
  ScheduledTask,
  ScheduledTaskCreate,
  ScheduledTaskUpdate,
  ParkingPermit,
  ParkingPermitCreate,
  ParkingPermitUpdate,
  ParkingPermitCheckNameExists,
  EnforcementReport,
  VerifyLicensePlateResponse,
  ParkingLotParkingPermitsSnapshot,
  EndUser,
  EndUserFullDetails,
  EndUserParkingPermitGrant,
  EndUserParkingPermitGrantCreate,
  EndUserParkingPermitGrantUpdate,
  InferenceRequestQueueResponse,
  CameraProcessingHistory,
  LicensePlateRecognitionHistory,
  VehicleParkingUsageTrackingAssociation,
  VehicleParkingUsageTrackingAssociationDetails,
  VehicleMatchingRequestQueue,
  VehicleMatchingRequestQueueUpdate,
  VehicleMatchingRequestQueueResponse,
  Tenant,
  TenantCreate,
  TenantUpdate,
  PrivateLotUser,
  PrivateLotUserCreate,
  PrivateLotUserUpdate,
  VehicleDetails,
  LprBlacklistWhitelistRecord,
  LprBlacklistWhitelistRecordCreate,
  LprBlacklistWhitelistRecordUpdate,
  NetworkVideoRecorders,
  NetworkVideoRecordersCreate,
  NetworkVideoRecordersUpdate,
  ServiceNodes,
  ServiceNodesCreate,
  ServiceNodesUpdate,
  NetworkDevices,
  NetworkDevicesCreate,
  NetworkDevicesUpdate,
  EdgeDevice,
  EdgeDeviceCreate,
  EdgeDeviceUpdate,
  EdgeDeviceType,
  EventSubscription,
  EventSubscriptionCreate,
  EventSubscriptionUpdate,
  EventSubscriptionsData,
  ThirdPartyEndpoint,
  ThirdPartyEndpointCreate,
  ThirdPartyEndpointUpdate,
  ThirdPartyEndpointValidate,
  ThirdPartyEndpointValidateResponse,
  ParkingLotDashboardOverview,
  ParkingLotDashboardDwellTime,
  ParkingLotDashboardViolationsResponse,
  EvInferenceRequestQueue,
  EvInferenceRequestQueueUpdate,
  ParkingSpotDetails,
  ParkingZoneDetails,
  ActivityAlertssDiyAppResponseV2,
  DigitalBoard,
  DigitalBoardCreate,
  DigitalBoardUpdate,
} from "./models";
import { AccuracyTrackingChartData } from "./models/AccuracyTracking";

export default {
  // Dashboard endpoints ///////////////////////////////////////////////////////

  async getOverviewData(
    lotId: number
  ): Promise<ParkingLotDashboardOverview | null> {
    try {
      const response = await axios.get<ParkingLotDashboardOverview>(
        `parking_lots/dashboard/${lotId}/overview`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getDwellTimeData(
    lotId: number
  ): Promise<ParkingLotDashboardDwellTime | null> {
    try {
      const response = await axios.get<ParkingLotDashboardDwellTime>(
        `parking_lots/dashboard/${lotId}/dwell_time`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getViolationsData(
    lotId: number
  ): Promise<ParkingLotDashboardViolationsResponse | null> {
    try {
      const response = await axios.get<ParkingLotDashboardViolationsResponse>(
        `parking_lots/dashboard/${lotId}/violations`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Parking Lot endpoints /////////////////////////////////////////////////////

  async getAllParkingLots(
    page?: number,
    itemsPerPage?: number,
    searchText?: string,
    sortBy?: string,
    filter_org_id?: number | null
  ): Promise<PaginatedItems<ParkingLotBasic> | null> {
    try {
      const filters: any = {};
      if (page != null) {
        filters["page"] = page;
      }
      if (itemsPerPage != null) {
        filters["size"] = itemsPerPage;
      }
      if (searchText != null) {
        filters["search_text"] = searchText;
      }
      if (sortBy != null) {
        filters["sort_by"] = sortBy;
      }
      if (filter_org_id != null) {
        filters["filter_org_id"] = filter_org_id;
      }
      const response = await axios.get("/parking_lots/", {
        params: filters,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getAllParkingLotsList(): Promise<Array<ParkingLotsList> | null> {
    try {
      const response = await axios.get("/parking_lots/all");
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getAllParkingLotsListForUser(
    is_enabled = false
  ): Promise<Array<ParkingLotsList> | null> {
    try {
      const response = await axios.get("/parking_lots/user/all", {
        params: {
          is_enabled,
        },
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingLotName(id: number): Promise<string | null> {
    try {
      const response = await axios.get<string>(`/parking_lots/${id}/name`);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingLot(id: number): Promise<ParkingLot | null> {
    try {
      const response = await axios.get<ParkingLot>(`/parking_lots/${id}`);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingLotBasicInfo(id: number): Promise<ParkingLotBasic | null> {
    try {
      const response = await axios.get<ParkingLotBasic>(`/parking_lots/${id}`);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingLotMapData(
    id: number,
    auth: string
  ): Promise<ParkingLot | null> {
    try {
      const response = await axios.get<ParkingLot>(`/parking_lots/${id}/map`, {
        params: { auth_token: auth },
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingLotMapStatsData(
    id: number
  ): Promise<ParkingLotMapStats | null> {
    try {
      const response = await axios.get<ParkingLotMapStats>(
        `/parking_lots/${id}/mapstatsdata`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingSpotHistory(
    lotId: number,
    date: string
  ): Promise<Array<ParkingLotHistory> | null> {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get<Array<ParkingLotHistory> | null>(
        `/parking_lots/${lotId}/spot_history`,
        {
          params: {
            date: date,
            timezone: timezone,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingSpotHistoryDaily(
    lotId: number,
    start_date: string,
    end_date: string
  ): Promise<Array<ParkingLotHistory> | null> {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get<Array<ParkingLotHistory> | null>(
        `/parking_lots/${lotId}/spot_history_daily`,
        {
          params: {
            start_date: start_date,
            end_date: end_date,
            timezone: timezone,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingSpotDetails(
    lotId: number,
    spotId: number,
    start_date: string,
    end_date: string
  ): Promise<ParkingSpotDetails | null> {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get<ParkingSpotDetails | null>(
        `/parking_lots/${lotId}/spots/${spotId}/spot_details`,
        {
          params: {
            start_date: start_date,
            end_date: end_date,
            timezone: timezone,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingSpotTimeline(
    lotId: number,
    spotId: number,
    show_violations: boolean,
    show_vacant: boolean,
    show_occupied: boolean,
    show_unknown: boolean,
    start_date: string,
    end_date: string,
    page: number,
    items_per_page: number
  ): Promise<any | null> {
    try {
      const filters: any = {};
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      filters["timezone"] = timezone;
      filters["show_violations"] = show_violations;
      filters["show_vacant"] = show_vacant;
      filters["show_occupied"] = show_occupied;
      filters["show_unknown"] = show_unknown;
      filters["start_date"] = start_date;
      filters["end_date"] = end_date;
      if (page != null) {
        filters["page"] = page;
      }
      if (items_per_page != null) {
        filters["size"] = items_per_page;
      }
      const response = await axios.get<any | null>(
        `/parking_lots/${lotId}/spots/${spotId}/spot_details_timeline`,
        {
          params: filters,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingZoneDetails(
    lotId: number,
    spotId: number,
    start_date: string,
    end_date: string
  ): Promise<ParkingZoneDetails | null> {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get<ParkingZoneDetails | null>(
        `/parking_lots/${lotId}/zones/${spotId}/zone_details`,
        {
          params: {
            start_date: start_date,
            end_date: end_date,
            timezone: timezone,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingZoneTimeline(
    lotId: number,
    spotId: number,
    show_violations: boolean,
    show_vacant: boolean,
    show_occupied: boolean,
    show_unknown: boolean,
    start_date: string,
    end_date: string,
    page: number,
    items_per_page: number
  ): Promise<any | null> {
    try {
      const filters: any = {};
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      filters["timezone"] = timezone;
      filters["show_violations"] = show_violations;
      filters["show_vacant"] = show_vacant;
      filters["show_occupied"] = show_occupied;
      filters["show_unknown"] = show_unknown;
      filters["start_date"] = start_date;
      filters["end_date"] = end_date;
      if (page != null) {
        filters["page"] = page;
      }
      if (items_per_page != null) {
        filters["size"] = items_per_page;
      }
      const response = await axios.get<any | null>(
        `/parking_lots/${lotId}/zones/${spotId}/zone_details_timeline`,
        {
          params: filters,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async exportDevicesData(parkingLotId: number): Promise<boolean> {
    try {
      const response = await axios.get(
        `/parking_lots/${parkingLotId}/export_devices`,
        { responseType: "blob" }
      );
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Devices_${parkingLotId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  async checkParkingLotName(
    name: ParkingLotNameCheck
  ): Promise<boolean | null> {
    try {
      const response = await axios.post("/parking_lots/check_name", name);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createParkingLot(
    parkingLot: ParkingLotCreate
  ): Promise<ParkingLotBasic | null> {
    try {
      const response = await axios.post("/parking_lots/", parkingLot);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateParkingLot(
    id: number,
    parkingLot: ParkingLotUpdate
  ): Promise<ParkingLot | null> {
    try {
      const response = await axios.put(`/parking_lots/${id}`, parkingLot);
      return response.data;
    } catch (error) {
      // if no lot boundary or parking zone exist
      if (error.response.status === 400) {
        throw error;
      }
      return null;
    }
  },

  async updateParkingLotOperatorContact(
    id: number,
    operatorContactNumber: string | null
  ): Promise<ParkingLot | null> {
    try {
      const response = await axios.put(`/parking_lots/${id}/operator_contact`, {
        operator_contact_number: operatorContactNumber,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async uploadParkingLotImage(
    parkingLotId: number,
    uploadedImgFile: File
  ): Promise<ParkingLotUploadedImagePaths | null> {
    try {
      const formData = new FormData();
      formData.append("new_lot_image_file", uploadedImgFile);
      const response = await axios.put(
        `/parking_lots/${parkingLotId}/upload_lot_image`,
        formData
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingLotStats(
    id: number,
    page: number,
    size: number,
    spotUpdates: boolean,
    flipflopUpdates: boolean,
    unstableUpdates: boolean,
    markedUnknown: boolean,
    roiUpdates: boolean,
    historyId: number | null,
    licensePlate: string | null,
    spotId?: Array<number>,
    cameraId?: Array<number>,
    zoneId?: Array<number>,
    specialAreaId?: Array<number>,
    dateRange?: Array<string>,
    timeRange?: Array<string>
  ): Promise<ParkingLotStats | null> {
    try {
      let queryParams = {};
      queryParams = page ? { page: page, ...queryParams } : { ...queryParams };

      queryParams = size ? { size: size, ...queryParams } : { ...queryParams };

      queryParams = historyId
        ? { history_id: historyId, ...queryParams }
        : { ...queryParams };

      queryParams = licensePlate
        ? { license_plate: licensePlate, ...queryParams }
        : { ...queryParams };

      queryParams = {
        spot_updates: spotUpdates,
        flipflop_updates: flipflopUpdates,
        unstable_updates: unstableUpdates,
        marked_unknown: markedUnknown,
        roi_updates: roiUpdates,
        ...queryParams,
      };

      queryParams =
        spotId && spotId.length > 0
          ? { spot_id: spotId.toString(), ...queryParams }
          : queryParams;

      queryParams =
        zoneId && zoneId.length > 0
          ? { zone_id: zoneId.toString(), ...queryParams }
          : queryParams;

      queryParams =
        specialAreaId && specialAreaId.length > 0
          ? { special_area_id: specialAreaId.toString(), ...queryParams }
          : queryParams;

      queryParams =
        cameraId && cameraId.length > 0
          ? { camera_id: cameraId.toString(), ...queryParams }
          : queryParams;

      if (dateRange && dateRange.length == 2) {
        let start_date = dateRange[0],
          end_date = dateRange[1];
        if (
          timeRange &&
          timeRange.length == 2 &&
          timeRange[0] &&
          timeRange[1]
        ) {
          start_date = dateRange[0] + " " + timeRange[0];
          end_date = dateRange[1] + " " + timeRange[1];
        } else {
          start_date = dateRange[0] + " 00:00";
          end_date = dateRange[1] + " 23:59";
        }

        queryParams = {
          start_date: `${start_date}`,
          end_date: `${end_date}`,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...queryParams,
        };
      }

      const response = await axios.get<ParkingLotStats>(
        `/parking_lots/${id}/stats`,
        { params: queryParams }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingLotStatsOccupancy(
    id: number,
    zoneId?: Array<number>
  ): Promise<Array<ParkingLotOccupancy> | null> {
    try {
      let queryParams = {};

      queryParams =
        zoneId && zoneId.length > 0
          ? { zone_id: zoneId.toString(), ...queryParams }
          : queryParams;

      const response = await axios.get<Array<ParkingLotOccupancy>>(
        `/parking_lots/${id}/stats/occupancy`,
        { params: queryParams }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getParkingLotSavedDetails(
    lotId: number
  ): Promise<ParkingLotSavedDetails | null> {
    try {
      const response = await axios.get<ParkingLotSavedDetails>(
        `/parking_lots/${lotId}/saved_details`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getDetailedOccupancies(
    lot_id: number,
    zone_id: number,
    startDate: string,
    endDate: string,
    intervalSeconds: number,
    parkingPermitId: number | null,
    area_type: string
  ): Promise<Array<ParkingLotZoneOccupancyCount> | null> {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get<Array<ParkingLotZoneOccupancyCount>>(
        `/parking_lots/occupancies/${lot_id}`,
        {
          params: {
            zone_id: zone_id > 0 ? zone_id : 0,
            start_date: startDate,
            end_date: endDate,
            interval_seconds: intervalSeconds,
            parking_permit_id: parkingPermitId,
            timezone: timezone,
            area_type: area_type,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getOccupanciesDwellTime(
    lot_id: number,
    zone_id: number,
    startDate: string,
    endDate: string,
    intervalSeconds: number,
    parkingPermitId: number | null,
    ignoreParkingSessionsUnder: number
  ): Promise<Array<ParkingLotDwellTime> | null> {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get<Array<ParkingLotDwellTime>>(
        `/parking_lots/occupancies_dwell_time/${lot_id}`,
        {
          params: {
            zone_id: zone_id > 0 ? zone_id : 0,
            start_date: startDate,
            end_date: endDate,
            interval_seconds: intervalSeconds,
            parking_permit_id: parkingPermitId,
            ignore_sessions: ignoreParkingSessionsUnder,
            timezone: timezone,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async exportDwellTimeChartData(
    lot_id: number,
    lot_name: string,
    zone_id: number,
    startDate: string,
    endDate: string,
    intervalSeconds: number,
    parkingPermitId: number | null
  ): Promise<boolean> {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get(
        `/parking_lots/occupancies_dwell_time/${lot_id}/export`,
        {
          params: {
            zone_id: zone_id > 0 ? zone_id : 0,
            start_date: startDate,
            end_date: endDate,
            interval_seconds: intervalSeconds,
            parking_permit_id: parkingPermitId,
            timezone: timezone,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const start_month = months[startDateObj.getMonth()];
      const start_day = startDateObj.getDate();
      const end_month = months[endDateObj.getMonth()];
      const end_day = endDateObj.getDate();
      const end_year = endDateObj.getFullYear();
      link.setAttribute(
        "download",
        `SpotGenius Dwell Time Report - ${
          start_month == end_month && start_day == end_day
            ? `${end_month} ${end_day} ${end_year}`
            : `${start_month} ${start_day} to ${end_month} ${end_day} ${end_year}`
        } - ${lot_name}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  async getOccupanciesParkingTurnover(
    lot_id: number,
    zone_id: number,
    startDate: string,
    endDate: string,
    intervalSeconds: number,
    parkingPermitId: number | null,
    ignoreUnmappedSpots: boolean
  ): Promise<Array<ParkingLotTurnover> | null> {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get<Array<ParkingLotTurnover>>(
        `/parking_lots/occupancies_parking_turnover/${lot_id}`,
        {
          params: {
            zone_id: zone_id > 0 ? zone_id : 0,
            start_date: startDate,
            end_date: endDate,
            interval_seconds: intervalSeconds,
            parking_permit_id: parkingPermitId,
            ignore_unmapped_spots: ignoreUnmappedSpots,
            timezone: timezone,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getAppUsageStats(
    lot_id: number,
    startDate: string,
    endDate: string,
    intervalSeconds: number
  ): Promise<Array<ParkingLotAppUsageCount> | null> {
    try {
      startDate = `${startDate.substring(0, 10)} 00:00`;
      endDate = `${endDate.substring(0, 10)} 23:59`;

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get<Array<ParkingLotAppUsageCount>>(
        `/parking_lots/app_usage/${lot_id}`,
        {
          params: {
            start_date: startDate,
            end_date: endDate,
            interval_seconds: intervalSeconds,
            timezone: timezone,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getTenantOccupancies(
    lot_id: number,
    startDate: string,
    endDate: string,
    intervalSeconds: number,
    tenant: number
  ): Promise<Array<ParkingLotTenantOccupancy> | null> {
    try {
      startDate = `${startDate.substring(0, 10)} 00:00`;
      endDate = `${endDate.substring(0, 10)} 23:59`;

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get<Array<ParkingLotTenantOccupancy>>(
        `/parking_lots/tenant_occupancies/${lot_id}`,
        {
          params: {
            start_date: startDate,
            end_date: endDate,
            interval_seconds: intervalSeconds,
            tenant_id: tenant,
            timezone: timezone,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getAccuracyTrackingMetricsData(
    lot_id: number,
    startDate: string,
    endDate: string,
    intervalSeconds: number,
    occupanyType: number
  ): Promise<AccuracyTrackingChartData | null> {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await axios.get(
        `/parking_lots/${lot_id}/accuracy_tracking/metrics`,
        {
          params: {
            lot_id: lot_id,
            start_date: startDate,
            end_date: endDate,
            interval_seconds: intervalSeconds,
            occupancy_type: occupanyType,
            timezone: timezone,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },
  async getParkingLotsAvgMetricsData(
    lot_ids: number[],
    startDate: string,
    endDate: string,
    intervalSeconds: number,
    occupanyType: number
  ) {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await axios.get(
        `/parking_lots/12345/accuracy_tracking/fetch_all_parking_avg_metrics`,
        {
          params: {
            start_date: startDate,
            end_date: endDate,
            interval_seconds: intervalSeconds,
            occupancy_type: occupanyType,
            parking_lot_ids: JSON.stringify(lot_ids),
            timezone: timezone,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },
  async getLotSnapshot(lot_id: number, timestamp: string) {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get(
        `/parking_lots/${lot_id}/accuracy_tracking/camera_data/${timestamp}`,
        {
          params: {
            lot_id: lot_id,
            timestamp: timestamp,
            timezone: timezone,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },
  async fetchAzureUsers(lot_id: number) {
    try {
      const response = await axios.get(
        `/parking_lots/${lot_id}/accuracy_tracking/fetch_azure_users`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },
  async verifyCameraData(
    lot_id: number,
    timestamp: string,
    camera_id: number,
    verified_spot_snapshot: Record<number, unknown>
  ) {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.post(
        `/parking_lots/${lot_id}/accuracy_tracking/update/${camera_id}/${timestamp}`,
        {
          params: {
            timestamp: timestamp,
            camera_id: camera_id,
            timezone: timezone,
            verified_spot_snapshot: verified_spot_snapshot,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async createOrUpdateAccuracyTrackingIssue(
    issue_data: Record<string, unknown>
  ) {
    try {
      const response = await axios.post(
        `/parking_lots/${issue_data["parking_lot_id"]}/accuracy_tracking/create_or_update_devops_issue`,
        issue_data
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async createOrUpdateSnapshotNote(
    parking_lot_id: number,
    timestamp: string,
    note: string
  ) {
    try {
      const response = await axios.put(
        `/parking_lots/${parking_lot_id}/accuracy_tracking/create_note_for/${timestamp}`,
        { note: note }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchATEnabledLots() {
    try {
      const response = await axios.get(
        `/parking_lots/12345/accuracy_tracking/fetch_all_lot_ids`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async uploadCameraMap(
    parkingLotId: number,
    nmDatasetJsonObj: any
  ): Promise<NmDatasetSaveResult | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${parkingLotId}/camera_maps_ann_json`,
        nmDatasetJsonObj
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async downloadCameraMap(parkingLotId: number): Promise<boolean> {
    try {
      const response = await axios.get(
        `/parking_lots/${parkingLotId}/camera_maps_ann_json`,
        { responseType: "blob" }
      );
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `camera_map_lot_id_${parkingLotId}.zip`);
      document.body.appendChild(link);
      link.click();
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  async deleteParkingLot(parkingLotId: number): Promise<boolean> {
    try {
      const response = await axios.delete(`/parking_lots/${parkingLotId}`);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  async getLotsWithUntrackedZonesInfo(): Promise<Array<ParkingLotUntrackedInfoOnly> | null> {
    try {
      const response = await axios.get("/parking_lots/untracked_info");
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getEnforcementReport(lotId: number): Promise<EnforcementReport | null> {
    try {
      if (!lotId) lotId = 0;
      const response = await axios.get(
        `/parking_lots/${lotId}/enforcement_report`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async markSpotPermitViolation(
    lotId: number,
    spotId: number
  ): Promise<boolean | null> {
    try {
      if (!lotId) lotId = 0;
      const response = await axios.post(
        `/parking_lots/${lotId}/mark_permit_violation`,
        {
          spot_id: spotId,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async verifyLicensePlate(
    spotId: number,
    licensePlateNumber: string
  ): Promise<VerifyLicensePlateResponse | null> {
    try {
      const response = await axios.get(
        `parking_lots/${spotId}/verify_license_plate`,
        {
          params: {
            license_plate_number: licensePlateNumber,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async configureNVRDetails(
    lotId: number,
    nvrDetails: Array<ParkingLotNvrDetailsCreate>
  ): Promise<OrganizationUser | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${lotId}/configure_nvr`,
        nvrDetails
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // LPR ///////////////////////////////////////////////////////////////////////

  async getLprHistory(
    license_plate_detected: string | null,
    parking_lot_id: number | null,
    camera_ids: string | null,
    lpr_direction: string | null,
    since_timestamp: string | null,
    upto_timestamp: string | null,
    page: number | null,
    size: number | null
  ): Promise<PaginatedItems<LicensePlateRecognitionHistory> | null> {
    try {
      const lprFilters: any = {};
      if (license_plate_detected) {
        lprFilters["license_plate_detected"] = license_plate_detected;
      }
      if (parking_lot_id) {
        lprFilters["parking_lot_id"] = parking_lot_id;
      }
      if (camera_ids) {
        lprFilters["camera_ids"] = camera_ids;
      }
      if (lpr_direction) {
        lprFilters["lpr_direction"] = lpr_direction;
      }
      if (since_timestamp) {
        lprFilters["since_timestamp"] = since_timestamp;
      }
      if (upto_timestamp) {
        lprFilters["upto_timestamp"] = upto_timestamp;
      }
      if (page != null) {
        lprFilters["page"] = page;
      }
      if (size != null) {
        lprFilters["size"] = size;
      }
      console.log("LPR filters: ", lprFilters);
      const response = await axios.get(`/lpr_history`, {
        params: lprFilters,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async getLprBlacklistWhitelist(
    lotId: number
  ): Promise<Array<LprBlacklistWhitelistRecord> | null> {
    try {
      const response = await axios.get(
        `/parking_lots/${lotId}/lpr_blacklist_whitelist`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async addLpToBlacklistWhitelist(
    lotId: number,
    lpRecordIn: LprBlacklistWhitelistRecordCreate
  ): Promise<LprBlacklistWhitelistRecord | string | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${lotId}/lpr_blacklist_whitelist`,
        lpRecordIn
      );
      return response.data;
    } catch (error) {
      return error?.response?.data?.detail;
    }
  },

  async updateLpToBlacklistWhitelist(
    lotId: number,
    lpRecordIn: LprBlacklistWhitelistRecordUpdate
  ): Promise<LprBlacklistWhitelistRecord | string | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${lotId}/lpr_blacklist_whitelist`,
        lpRecordIn
      );
      return response.data;
    } catch (error) {
      return error?.response?.data?.detail;
    }
  },

  async deleteLpFromBlacklistWhitelist(
    lotId: number,
    lpListRecordId: number
  ): Promise<boolean> {
    try {
      const response = await axios.delete(
        `/parking_lots/${lotId}/lpr_blacklist_whitelist/${lpListRecordId}`
      );
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  // Clear Non spot saved parking
  async clearBlockedParking(
    lotId: number,
    savedNonSpotId: number
  ): Promise<boolean> {
    try {
      const response = await axios.delete(
        `/parking_lots/${lotId}/save_non_spot/${savedNonSpotId}`
      );
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  // Vehicle Parking Usage Tracking Association ////////////////////////////////

  async getVehicleParkingUsageRecords(
    license_plate_detected: string | null,
    camera_id: number | null,
    vehicle_brand: string | null,
    vehicle_color: string | null,
    vehicle_type: string | null,
    parking_lot_id: number | null,
    since_timestamp: string | null,
    upto_timestamp: string | null,
    time_sliced: boolean | null,
    is_verified: boolean | null,
    record_id: number | null,
    record_id_match: string | null,
    entry_exit_pair: string | null,
    lot_usage_time: number | null,
    lot_usage_time_match: string | null,
    parking_usage_time: number | null,
    parking_usage_time_match: string | null,
    sortBy: string | null,
    sortOrder: boolean | null,
    page: number | null,
    size: number | null
  ): Promise<PaginatedItems<VehicleParkingUsageTrackingAssociation> | null> {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const filters: any = {};
      if (license_plate_detected) {
        filters["license_plate_detected"] = license_plate_detected;
      }
      if (camera_id) {
        filters["camera_id"] = camera_id;
      }
      if (vehicle_brand) {
        filters["vehicle_brand"] = vehicle_brand;
      }
      if (vehicle_color) {
        filters["vehicle_color"] = vehicle_color;
      }
      if (vehicle_type) {
        filters["vehicle_type"] = vehicle_type;
      }
      if (parking_lot_id) {
        filters["parking_lot_id"] = parking_lot_id;
      }
      if (since_timestamp) {
        filters["since_timestamp"] = since_timestamp;
      }
      if (upto_timestamp) {
        filters["upto_timestamp"] = upto_timestamp;
      }
      if (timezone) {
        filters["timezone"] = timezone;
      }
      if (time_sliced != null) {
        filters["time_sliced"] = time_sliced;
      }
      if (is_verified !== null) {
        filters["is_verified"] = is_verified;
      }
      if (record_id) {
        filters["record_id"] = record_id;
      }
      if (record_id_match) {
        filters["record_id_match"] = record_id_match;
      }
      if (entry_exit_pair) {
        filters["entry_exit_pair"] = entry_exit_pair;
      }
      if (lot_usage_time) {
        filters["lot_usage_time"] = lot_usage_time;
      }
      if (lot_usage_time_match) {
        filters["lot_usage_time_match"] = lot_usage_time_match;
      }
      if (parking_usage_time) {
        filters["parking_usage_time"] = parking_usage_time;
      }
      if (parking_usage_time_match) {
        filters["parking_usage_time_match"] = parking_usage_time_match;
      }
      if (sortBy) {
        filters["sort_by"] = sortBy;
      }
      if (sortOrder != null) {
        filters["sort_order"] = sortOrder;
      }
      if (page != null) {
        filters["page"] = page;
      }
      if (size != null) {
        filters["size"] = size;
      }
      console.log("LPR filters: ", filters);
      const response = await axios.get(`/vehicle_parking_usage`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async getVehicleParkingUsageRecordDetails(
    recordId: number
  ): Promise<VehicleParkingUsageTrackingAssociationDetails | null> {
    try {
      const response = await axios.get(
        `/vehicle_parking_usage/${recordId}`,
        {}
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async putVehicleParkingUsageRecordDetails(
    recordId: number,
    data: any
  ): Promise<VehicleParkingUsageTrackingAssociationDetails | null> {
    try {
      const response = await axios.put(
        `/vehicle_parking_usage/${recordId}`,
        data
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async approveVehicleParkingUsageRecordDetails(
    recordId: number
  ): Promise<VehicleParkingUsageTrackingAssociationDetails | null> {
    try {
      const response = await axios.post(
        `/vehicle_parking_usage/${recordId}/approve`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async rejectVehicleParkingUsageRecordDetails(
    recordId: number
  ): Promise<VehicleParkingUsageTrackingAssociationDetails | null> {
    try {
      const response = await axios.post(
        `/vehicle_parking_usage/${recordId}/reject`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  // Vehicle Parking Usage Tracking Association ////////////////////////////////

  async getVehicleMatchingAnprRecord(
    record_id: number
  ): Promise<VehicleMatchingRequestQueueResponse | null> {
    try {
      const response = await axios.get(
        `/vehicle_matching_anpr_requests/${record_id}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async getVehicleMatchingAnprRequests(
    since_timestamp: string | null,
    upto_timestamp: string | null,
    time_sliced: boolean | null,
    parking_lot_id: number | null,
    camera_id: number | null,
    spot_id: number | null,
    record_id: number | null,
    record_id_match: string | null,
    top_matching_score: number | null,
    top_matching_score_match: string | null,
    lpr_id: number | null,
    license_plate_number: string | null,
    verified: boolean | null,
    ai_predicted: boolean | null,
    ai_matched_correctly: boolean | null,
    matching_status: string | null,
    order_by: string | null,
    page: number | null,
    size: number | null
  ): Promise<PaginatedItems<VehicleMatchingRequestQueue> | null> {
    try {
      const filters: any = {};
      if (since_timestamp) {
        filters["since_timestamp"] = since_timestamp;
      }
      if (upto_timestamp) {
        filters["upto_timestamp"] = upto_timestamp;
      }
      if (time_sliced != null) {
        filters["time_sliced"] = time_sliced;
      }
      if (parking_lot_id != null) {
        filters["parking_lot_id"] = parking_lot_id;
      }
      if (camera_id != null) {
        filters["camera_id"] = camera_id;
      }
      if (spot_id != null) {
        filters["spot_id"] = spot_id;
      }
      if (record_id != null) {
        filters["record_id"] = record_id;
      }
      if (record_id_match != null) {
        filters["record_id_match"] = record_id_match;
      }
      if (top_matching_score != null) {
        filters["top_matching_score"] = top_matching_score;
      }
      if (top_matching_score_match != null) {
        filters["top_matching_score_match"] = top_matching_score_match;
      }
      if (lpr_id != null) {
        filters["lpr_id"] = lpr_id;
      }
      if (license_plate_number != null) {
        filters["license_plate_number"] = license_plate_number;
      }
      if (verified != null) {
        filters["verified"] = verified;
      }
      if (ai_predicted != null) {
        filters["ai_predicted"] = ai_predicted;
      }
      if (ai_matched_correctly != null) {
        filters["ai_matched_correctly"] = ai_matched_correctly;
      }
      if (matching_status != null) {
        filters["matching_status"] = matching_status;
      }
      if (order_by != null) {
        filters["order_by"] = order_by;
      }
      if (page != null) {
        filters["page"] = page;
      }
      if (size != null) {
        filters["size"] = size;
      }
      console.log("Filters: ", filters);
      const response = await axios.get(`/vehicle_matching_anpr_requests`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async getVehicleMatchingAnprRequestsAccuracy(
    since_timestamp: string | null,
    upto_timestamp: string | null,
    time_sliced: boolean | null,
    parking_lot_id: number | null,
    camera_id: number | null,
    spot_id: number | null,
    record_id: number | null,
    record_id_match: string | null,
    top_matching_score: number | null,
    top_matching_score_match: string | null,
    lpr_id: number | null,
    license_plate_number: string | null,
    verified: boolean | null,
    ai_predicted: boolean | null,
    ai_matched_correctly: boolean | null,
    matching_status: string | null,
    page: number | null,
    size: number | null
  ): Promise<any | null> {
    try {
      const filters: any = {};
      if (since_timestamp) {
        filters["since_timestamp"] = since_timestamp;
      }
      if (upto_timestamp) {
        filters["upto_timestamp"] = upto_timestamp;
      }
      if (time_sliced != null) {
        filters["time_sliced"] = time_sliced;
      }
      if (parking_lot_id != null) {
        filters["parking_lot_id"] = parking_lot_id;
      }
      if (camera_id != null) {
        filters["camera_id"] = camera_id;
      }
      if (spot_id != null) {
        filters["spot_id"] = spot_id;
      }
      if (record_id != null) {
        filters["record_id"] = record_id;
      }
      if (record_id_match != null) {
        filters["record_id_match"] = record_id_match;
      }
      if (top_matching_score != null) {
        filters["top_matching_score"] = top_matching_score;
      }
      if (top_matching_score_match != null) {
        filters["top_matching_score_match"] = top_matching_score_match;
      }
      if (lpr_id != null) {
        filters["lpr_id"] = lpr_id;
      }
      if (license_plate_number != null) {
        filters["license_plate_number"] = license_plate_number;
      }
      if (verified != null) {
        filters["verified"] = verified;
      }
      if (ai_predicted != null) {
        filters["ai_predicted"] = ai_predicted;
      }
      if (ai_matched_correctly != null) {
        filters["ai_matched_correctly"] = ai_matched_correctly;
      }
      if (matching_status != null) {
        filters["matching_status"] = matching_status;
      }
      if (page != null) {
        filters["page"] = page;
      }
      if (size != null) {
        filters["size"] = size;
      }
      filters["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log("Filters: ", filters);
      const response = await axios.get(
        `/vehicle_matching_anpr_requests/accuracy`,
        {
          params: filters,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async putVehicleMatchingAnprRequests(
    recordId: number,
    data: any
  ): Promise<VehicleMatchingRequestQueue | null> {
    try {
      const response = await axios.put(
        `/vehicle_matching_anpr_requests/${recordId}`,
        data
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  // Ev Inference Records //////////////////////////////////////////////////////

  async getEvChargingInferenceRequests(
    since_timestamp: string | null,
    upto_timestamp: string | null,
    parking_lot_id: number | null,
    camera_id: number | null,
    spot_id: number | null,
    conf_output_score_greater_than: number,
    conf_output_score_lesser_than: number,
    is_verified: boolean | null,
    ai_predicted_correctly: boolean | null,
    ai_predicted_incorrectly: boolean | null,
    request_status: string | null,
    order_by: string | null,
    page: number | null,
    size: number | null
  ): Promise<PaginatedItems<EvInferenceRequestQueue> | null> {
    try {
      const filters: any = {};
      if (page != null) {
        filters["page"] = page;
      }
      if (size != null) {
        filters["size"] = size;
      }
      console.log("Filters: ", filters);
      const response = await axios.get(`/ev_charger_inference_requests`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async putEvChargingInferenceRequests(
    recordId: number,
    data: any
  ): Promise<EvInferenceRequestQueueUpdate | null> {
    try {
      const response = await axios.put(
        `/ev_charger_inference_requests/${recordId}`,
        data
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  // Camera endpoints //////////////////////////////////////////////////////////

  async getAllCameras(
    parkingLotId: number,
    lpr_only = false
  ): Promise<Array<Camera> | null> {
    try {
      const response = await axios.get<Array<Camera>>(
        `/parking_lots/${parkingLotId}/cameras`,
        {
          params: {
            lpr_only,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getAllCamerasList(
    parkingLotId: number | null
  ): Promise<Array<CamerasList> | null> {
    try {
      const response = await axios.get(
        `/parking_lots/${parkingLotId}/cameras/all`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getAllCamerasAssignedToUntrackedZone(
    parkingLotId: number,
    untrackedZoneId: number
  ): Promise<Array<Camera> | null> {
    try {
      const response = await axios.get<Array<Camera>>(
        `/parking_lots/${parkingLotId}/zones/get_untracked_zone_cameras/${untrackedZoneId}`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createCamera(camera: CameraCreate): Promise<Camera | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${camera.parking_lot_id}/cameras`,
        camera
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateCamera(camera: CameraUpdate): Promise<Camera | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${camera.parking_lot_id}/cameras/${camera.id}`,
        camera
      );
      return response.data;
    } catch (error) {
      // When no AI server capacity to start processing camera
      if (error.response.status === 503) {
        throw error;
      }
      return null;
    }
  },

  async setCameraRefinement(
    parking_lot_id: number,
    camera_id: number
  ): Promise<Camera | null> {
    const response = await axios.put(
      `/parking_lots/${parking_lot_id}/cameras/${camera_id}/camera_refinement`
    );
    return response.data;
  },

  async deleteCamera(parkingLotId: number, cameraId: number): Promise<boolean> {
    try {
      const response = await axios.delete(
        `/parking_lots/${parkingLotId}/cameras/${cameraId}`
      );
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  async downloadProposedCameraMap(
    parkingLotId: number,
    cameraId: number
  ): Promise<boolean> {
    try {
      const response = await axios.get(
        `/parking_lots/${parkingLotId}/cameras/${cameraId}/proposed_camera_map_ann_json`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `proposed_camera_map_id_${cameraId}.zip`);
      document.body.appendChild(link);
      link.click();
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  async downloadFrame(lotId: number, cameraId: number): Promise<string | null> {
    try {
      const response = await axios.get(
        `/parking_lots/${lotId}/cameras/${cameraId}/download_frame`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "image/jpeg",
        })
      );
      return url;
    } catch (error) {
      if (error.response.status === 403) {
        // When Camera is unstable
        throw error;
      }
      return null;
    }
  },

  async getCameraProcessingHistory(
    parkingLotId: number,
    cameraId: number,
    page?: number,
    itemsPerPage?: number
  ): Promise<PaginatedItems<CameraProcessingHistory> | null> {
    try {
      const filters: any = {};
      if (page != null) {
        filters["page"] = page;
      }
      if (itemsPerPage != null) {
        filters["size"] = itemsPerPage;
      }
      const response = await axios.get(
        `/parking_lots/${parkingLotId}/cameras/${cameraId}/processing_history`,
        {
          params: filters,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getCameraMapDetails(
    parkingLotId: number,
    cameraId: number
  ): Promise<CameraMapDetails | null> {
    try {
      const response = await axios.get<CameraMapDetails>(
        `/parking_lots/${parkingLotId}/cameras/${cameraId}/camera_map`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateCameraMapDetails(
    parkingLotId: number,
    cameraId: number,
    cameraMap: any
  ): Promise<CameraMapDetails | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${parkingLotId}/cameras/${cameraId}/camera_map`,
        cameraMap
      );
      return response.data;
    } catch (error) {
      // When no AI server capacity to start processing camera
      if (error.response.status === 503) {
        throw error;
      }
      return null;
    }
  },

  // Import image into camera map editor and run vehicle detection inference on
  // it to also fetch bbox detections.

  async importCameraMapImageFromLatestFrame(
    parkingLotId: number,
    cameraId: number
  ): Promise<InferenceRequestQueueResponse | null> {
    try {
      const response = await axios.get<InferenceRequestQueueResponse>(
        `/parking_lots/${parkingLotId}/cameras/${cameraId}/import_camera_map_frame/latest`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async importCameraMapImageFromHighestVehicleCount(
    parkingLotId: number,
    cameraId: number
  ): Promise<InferenceRequestQueueResponse | null> {
    try {
      const response = await axios.get<InferenceRequestQueueResponse>(
        `/parking_lots/${parkingLotId}/cameras/${cameraId}/import_camera_map_frame/highest_vehicle_count`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async importCameraMapImageFromUploadedImage(
    parkingLotId: number,
    cameraId: number,
    uploadedImgFile: File
  ): Promise<InferenceRequestQueueResponse | null> {
    try {
      const formData = new FormData();
      formData.append("input_image_file", uploadedImgFile);
      const response = await axios.put(
        `/parking_lots/${parkingLotId}/cameras/${cameraId}/import_camera_map_frame/uploaded_image`,
        formData
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async uploadCameraReferenceImage(
    parkingLotId: number,
    cameraId: number,
    uploadedImgFile: File
  ): Promise<string | null> {
    try {
      const formData = new FormData();
      formData.append("new_reference_image_file", uploadedImgFile);
      const response = await axios.put(
        `/parking_lots/${parkingLotId}/cameras/${cameraId}/reference_image`,
        formData
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getCameraDetails(
    parkingLotId: number,
    cameraId: number
  ): Promise<CameraDetails | null> {
    try {
      const response = await axios.get<CameraDetails>(
        `/parking_lots/${parkingLotId}/cameras/${cameraId}/camera_details`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async saveCameraDetails(
    parkingLotId: number,
    cameraId: number,
    cameraDetails: CameraDetails
  ): Promise<CameraDetails | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${parkingLotId}/cameras/${cameraId}/camera_details`,
        cameraDetails
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Servers ///////////////////////////////////////////////////////////////////

  async getAllServers(): Promise<Array<Server> | null> {
    try {
      const response = await axios.get("/servers");
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Edge Devices //////////////////////////////////////////////////////////////

  async getAvailableEdgeDeviceTypes(
    lotId: number
  ): Promise<Array<EdgeDeviceType> | null> {
    try {
      const response = await axios.get(
        `/parking_lots/${lotId}/edge_devices/available_device_types`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getAllEdgeDevices(lotId: number): Promise<Array<EdgeDevice> | null> {
    try {
      const response = await axios.get(`/parking_lots/${lotId}/edge_devices`);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createEdgeDevice(
    lotId: number,
    edgeDevice: EdgeDeviceCreate
  ): Promise<EdgeDevice | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${lotId}/edge_devices`,
        edgeDevice
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateEdgeDevice(
    lotId: number,
    edgeDevice: EdgeDeviceUpdate
  ): Promise<Message | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${lotId}/edge_devices/${edgeDevice.id}`,
        edgeDevice
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async replaceEdgeDevice(
    lotId: number,
    edgeDevice: EdgeDevice
  ): Promise<EdgeDevice | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${lotId}/edge_devices/replace`,
        edgeDevice
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async deleteEdgeDevice(
    lotId: number,
    edgeDeviceId: string
  ): Promise<boolean | null> {
    try {
      await axios.delete(`/parking_lots/${lotId}/edge_devices/${edgeDeviceId}`);
      return true;
    } catch (error) {
      return null;
    }
  },

  async updateEdgeDevicePlateRecognizerConfig(
    lotId: number,
    edgeDeviceId: string
  ): Promise<boolean | null> {
    try {
      const response = await axios.get(
        `/parking_lots/${lotId}/edge_devices/${edgeDeviceId}/update_plate_recognizer_config`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Pipeline Testbench ////////////////////////////////////////////////////////

  async getAllTestbenchRecords(): Promise<Array<any> | null> {
    try {
      const response = await axios.get("/inference_pipeline_testbench");
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getTestbenchRecord(testId: number): Promise<Array<any> | null> {
    try {
      const response = await axios.get(
        `/inference_pipeline_testbench/${testId}`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async uploadTestbenchRequest(
    cameraId: number,
    executionEnv: string,
    pipeline_type: string,
    uploadedImageFiles: Array<File>
  ): Promise<InferenceRequestQueueResponse | null> {
    try {
      const formData = new FormData();
      formData.append("camera_id", String(cameraId));
      formData.append("execution_env", executionEnv);
      formData.append("pipeline_type", pipeline_type);
      for (const uploadedImageFile of uploadedImageFiles) {
        formData.append("input_files", uploadedImageFile);
      }
      const response = await axios.post(
        "/inference_pipeline_testbench",
        formData
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Parking Spots /////////////////////////////////////////////////////////////

  async updateParkingSpot(
    parkingSpot: ParkingSpotUpdate
  ): Promise<ParkingSpot | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${parkingSpot.parking_lot_id}/spots/${parkingSpot.id}`,
        parkingSpot
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async reportSpotInaccuracy(
    spotInaccuracy: ParkingSpotInaccuracy
  ): Promise<ParkingSpot | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${spotInaccuracy.parking_lot_id}/spots/${spotInaccuracy.spot_id}/report_inaccuracy`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Parking Zones /////////////////////////////////////////////////////////////

  async updateParkingZone(
    parkingZone: ParkingZoneUpdate
  ): Promise<ParkingZone | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${parkingZone.parking_lot_id}/zones/${parkingZone.id}`,
        parkingZone
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateUntrackedZoneCount(
    parkingLotId: number,
    parkingZoneId: number,
    parkingZoneUpdate: ParkingZoneUntrackedInfoOnlyUpdate
  ): Promise<ParkingZoneUntrackedInfoOnly | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${parkingLotId}/zones/${parkingZoneId}/untracked_count`,
        parkingZoneUpdate
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Special Area //////////////////////////////////////////////////////////////

  async updateSpecialArea(
    specialArea: SpecialAreaUpdate
  ): Promise<SpecialArea | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${specialArea.parking_lot_id}/special_areas/${specialArea.id}`,
        specialArea
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Parking Lanes /////////////////////////////////////////////////////////////

  async updateParkingLane(
    parkingLane: ParkingLaneUpdate
  ): Promise<ParkingLane | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${parkingLane.parking_lot_id}/lanes/${parkingLane.id}`,
        parkingLane
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Org ///////////////////////////////////////////////////////////////////////

  async getAllOrgs(): Promise<Array<Organization> | null> {
    try {
      const response = await axios.get("/organizations");
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getAllCustomerOrgs(): Promise<Array<Organization> | null> {
    try {
      const response = await axios.get("/organizations/customer");
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createOrg(newOrg: OrganizationCreate): Promise<Organization | null> {
    try {
      const response = await axios.post("/organizations", newOrg);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateOrg(
    orgId: number,
    updatedOrg: OrganizationUpdate
  ): Promise<Organization | null> {
    try {
      const response = await axios.put(`/organizations/${orgId}`, updatedOrg);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Org User //////////////////////////////////////////////////////////////////

  async loginOrgUser(
    loginPayload: OrganizationUserLoginPayload
  ): Promise<OrganizationUserLoginResponseToken | null> {
    try {
      const loginPayloadFormData = new FormData();
      loginPayloadFormData.append("username", loginPayload.username);
      loginPayloadFormData.append("password", loginPayload.password);
      const response = await axios.post<OrganizationUserLoginResponseToken>(
        "/login/org-access-token",
        loginPayloadFormData
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        throw error;
      }
      return null;
    }
  },

  async getMaintenanceWindowInfo(): Promise<MaintenanceWindow | null> {
    try {
      const response = await axios.get("/maintenance_info");
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getCurrentOrgUser(): Promise<OrganizationUserWithExtraData | null> {
    try {
      const response = await axios.get("/org_users/me");
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createOrgUser(
    newOrgUser: OrganizationUserCreate
  ): Promise<OrganizationUser | null> {
    try {
      const response = await axios.post("/org_users", newOrgUser);
      return response.data;
    } catch (error) {
      return error?.response?.data?.detail;
    }
  },

  async updateOrgUser(
    orgUserId: number,
    updatedOrgUser: OrganizationUserUpdate
  ): Promise<OrganizationUser | null> {
    try {
      const response = await axios.put(
        `/org_users/${orgUserId}`,
        updatedOrgUser
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async changeOrgUserPassword(
    updatedOrgUser: OrganizationUserUpdate
  ): Promise<OrganizationUser | null> {
    try {
      const response = await axios.put(`/org_users/me`, updatedOrgUser);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateOrgUserEmailPreferences(
    orgUserId: number,
    updatedOrgUser: OrganizationUserUpdate
  ): Promise<OrganizationUser | null> {
    try {
      const response = await axios.put(
        `/org_users/${orgUserId}/email_preferences`,
        updatedOrgUser
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async deleteOrgUser(orgUserId: number): Promise<boolean> {
    try {
      const response = await axios.delete(`/org_users/${orgUserId}`);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  // Org User Authorization ////////////////////////////////////////////////////

  async getAuthorizationsForOrgUser(
    orgUserId: number
  ): Promise<Array<OrganizationUserAuthorization> | null> {
    try {
      const response = await axios.get(`/org_user_authorizations/${orgUserId}`);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async grantOrgUserAuthorization(
    orgUserId: number,
    parkingLotId: number | null,
    organizationId: number | null
  ): Promise<OrganizationUserAuthorization | null> {
    try {
      const response = await axios.post("/org_user_authorizations", {
        organization_user_id: orgUserId,
        parking_lot_id: parkingLotId,
        organization_id: organizationId,
      });
      return response.data;
    } catch (error) {
      return error?.response?.data?.detail;
    }
  },

  async revokeOrgUserAuthorization(authorizationId: number) {
    try {
      const response = await axios.delete(
        `/org_user_authorizations/${authorizationId}`
      );
      return response.data;
    } catch (error) {
      return error?.response?.data?.detail;
    }
  },

  // User Activity Audit Logs //////////////////////////////////////////////////

  async getAllUserActivityLogs(
    userEmail?: string,
    lotId?: number | null,
    dateRange?: Array<string>,
    page?: number,
    itemsPerPage?: number
  ): Promise<PaginatedItems<UserActivityAuditLog> | null> {
    try {
      const filters: any = {};
      if (userEmail) {
        filters["user_email"] = userEmail;
      }
      if (lotId) {
        filters["parking_lot_id"] = lotId.toString();
      }
      if (dateRange && dateRange.length == 2) {
        filters["start_date"] = `${dateRange[0]} 00:00`;
        filters["end_date"] = `${dateRange[1]} 23:59`;
        filters["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      if (page != null) {
        filters["page"] = page;
      }
      if (itemsPerPage != null) {
        filters["size"] = itemsPerPage;
      }
      const response = await axios.get("/user_activity_logs", {
        params: filters,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // OAuth 2 ///////////////////////////////////////////////////////////////////

  async generateOAuthClient(data: any): Promise<any> {
    try {
      const response = await axios.post("/oauth/client", data);
      return response.data;
    } catch (error) {
      return error?.response?.data?.detail;
    }
  },

  async getAllOauthClients(): Promise<any> {
    try {
      const response = await axios.get("/oauth/client");
      return response.data;
    } catch (error) {
      return error?.response?.data?.detail;
    }
  },

  // Alerts ////////////////////////////////////////////////////////////////////

  async getAlert(alertId: number, superAdminViewSwitched?: boolean | null) {
    try {
      const filters: any = {};
      if (superAdminViewSwitched) {
        filters["admin_alerts"] = superAdminViewSwitched;
      }
      const response = await axios.get(`/alerts/${alertId}`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      //if user does not have access to the alert
      if (error.response.status === 403) {
        throw error;
      }
      return null;
    }
  },

  async getAllAlerts(
    search_text?: string,
    severity?: string,
    category?: string,
    archived?: boolean,
    oldestFirst?: boolean,
    lotIds?: Array<number>,
    cameraIds?: Array<number>,
    alertTypes?: Array<string>,
    dateRange?: Array<string>,
    anprId?: number | null,
    superAdminViewSwitched?: boolean | null,
    show_active_camera_issues?: boolean | null,
    page?: number,
    itemsPerPage?: number
  ): Promise<AlertsPaginated | null> {
    try {
      const filters: any = {};
      if (search_text) {
        filters["search_text"] = search_text;
      }
      if (severity) {
        filters["severity"] = severity;
      }
      if (category) {
        filters["category"] = category;
      }

      filters["archived"] = archived || false;

      if (oldestFirst) {
        filters["oldest_first"] = oldestFirst;
      }

      if (lotIds) {
        filters["lot_ids"] = lotIds.toString();
      }

      if (cameraIds) {
        filters["camera_ids"] = cameraIds.toString();
      }

      if (alertTypes) {
        filters["alert_types"] = alertTypes.toString();
      }

      if (dateRange && dateRange.length == 2) {
        filters["start_date"] = `${dateRange[0]} 00:00`;
        filters["end_date"] = `${dateRange[1]} 23:59`;
        filters["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      if (anprId) {
        filters["anpr_id"] = anprId;
      }
      if (superAdminViewSwitched) {
        filters["admin_alerts"] = superAdminViewSwitched;
      }
      if (show_active_camera_issues) {
        filters["show_active_camera_issues"] = show_active_camera_issues;
      }

      if (page != null) {
        filters["page"] = page;
      }
      if (itemsPerPage != null) {
        filters["size"] = itemsPerPage;
      }
      const response = await axios.get("/alerts", {
        params: filters,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async doAlertAction(
    alertId: number,
    actionValue: boolean
  ): Promise<Alert | null> {
    try {
      const response = await axios.get(`/alerts/${alertId}/do_action`, {
        params: {
          action_value: actionValue,
        },
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async takeAlertAction(
    alertId: number,
    alertAction: string,
    azureUserEmail: string,
    createUpdateIssue: string,
    prevIssueId: number | null
  ): Promise<Alert | null> {
    if (!prevIssueId) prevIssueId = 0;
    const response = await axios.get(`/alerts/${alertId}/take_action`, {
      params: {
        action: alertAction,
        azure_user_email: azureUserEmail,
        create_or_update_issue: createUpdateIssue,
        prev_issue_id: prevIssueId,
      },
    });
    return response.data;
  },

  async checkAlertEscalated(
    alertId: number
  ): Promise<CheckEscalatedAlert | null> {
    try {
      const response = await axios.get(
        `/alerts/${alertId}/check_alert_escalated`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async archiveUnarchiveAlert(
    alertId: number,
    actionValue: boolean
  ): Promise<Alert | null> {
    try {
      const response = await axios.get(`/alerts/${alertId}/mark_archived`, {
        params: {
          action_value: actionValue,
        },
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async generateAlert(alert: GenerateAlert): Promise<Alert | null> {
    try {
      const response = await axios.post("/alerts/generate_alert", alert);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async forwardAlert(alert_id: number, comment: string): Promise<Alert | null> {
    try {
      const response = await axios.post(`/alerts/${alert_id}/forward_alert`, {
        comment: comment,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Alert Types ///////////////////////////////////////////////////////////////

  async getAllAlertTypes(
    user_type: OrganizationUserType
  ): Promise<Array<AlertTypes> | null> {
    try {
      const filters: any = {};
      if (user_type) {
        filters["user_type"] = user_type;
      }
      const response = await axios.get("/alert_types", {
        params: filters,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // App Messages //////////////////////////////////////////////////////////////

  async getAllMessages(lot_id?: number): Promise<Array<Message> | null> {
    try {
      const filters: any = {};
      if (lot_id) {
        filters["lot_id"] = lot_id;
      }
      const response = await axios.get("/app_messages", {
        params: filters,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async postNewMessage(message: MessageCreate): Promise<Message | null> {
    try {
      const response = await axios.post("/app_messages", message);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async putMessage(
    messageId: number,
    message: MessageUpdate
  ): Promise<Message | null> {
    try {
      const response = await axios.put(`/app_messages/${messageId}`, message);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async deleteMessage(messageId: number): Promise<boolean | null> {
    try {
      await axios.delete(`/app_messages/${messageId}`);
      return true;
    } catch (error) {
      return null;
    }
  },

  async getMessageTemplates(
    lot_id?: number,
    alert_type_id?: number
  ): Promise<Array<MessageTemplate> | null> {
    try {
      const filters: any = {};
      if (lot_id) {
        filters["lot_id"] = lot_id;
      }
      if (alert_type_id) {
        filters["alert_type_id"] = alert_type_id;
      }
      const response = await axios.get("/app_messages/app_message_templates", {
        params: filters,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Scheduled Tasks ///////////////////////////////////////////////////////////

  async getAllScheduledTasks(): Promise<Array<ScheduledTask> | null> {
    try {
      const response = await axios.get("/scheduled_tasks");
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createNewScheduledTask(
    newScheduledTask: ScheduledTaskCreate
  ): Promise<ScheduledTask | null> {
    try {
      const response = await axios.post("/scheduled_tasks", newScheduledTask);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateScheduledTask(
    scheduledTaskId: number,
    scheduledTask: ScheduledTaskUpdate
  ): Promise<ScheduledTask | null> {
    try {
      const response = await axios.put(
        `/scheduled_tasks/${scheduledTaskId}`,
        scheduledTask
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async deleteScheduledTask(scheduledTaskId: number): Promise<boolean | null> {
    try {
      await axios.delete(`/scheduled_tasks/${scheduledTaskId}`);
      return true;
    } catch (error) {
      return null;
    }
  },

  // Parking Permits ///////////////////////////////////////////////////////////

  async getAllParkingPermits(
    lotId: number,
    validPermitsOnly = false
  ): Promise<Array<ParkingPermit> | null> {
    try {
      const filters: any = {};
      filters["valid_permits_only"] = validPermitsOnly;
      const response = await axios.get(`/parking_lots/${lotId}/permits`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createNewParkingPermit(
    newParkingPermit: ParkingPermitCreate
  ): Promise<ParkingPermit | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${newParkingPermit.parking_lot_id}/permits`,
        newParkingPermit
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  /*
   * Return true if another parking permit with same name exists in given lot ID, else false.
   */
  async checkParkingPermitNameLotIdExists(
    parkingPermitDetails: ParkingPermitCheckNameExists
  ): Promise<boolean> {
    try {
      const response = await axios.post(
        `/parking_lots/${parkingPermitDetails.parking_lot_id}/permits/check_name_exists`,
        parkingPermitDetails
      );
      return false;
    } catch (error) {
      return true;
    }
  },

  async updateParkingPermit(
    parkingPermitId: number,
    parkingPermit: ParkingPermitUpdate
  ): Promise<ParkingPermit | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${parkingPermit.parking_lot_id}/permits/${parkingPermitId}`,
        parkingPermit
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async deleteParkingPermit(
    lotId: number,
    parkingPermitId: number
  ): Promise<boolean | null> {
    try {
      await axios.delete(`/parking_lots/${lotId}/permits/${parkingPermitId}`);
      return true;
    } catch (error) {
      return null;
    }
  },

  async getAllParkingPermitSnapshots(
    lotId: number
  ): Promise<Array<ParkingLotParkingPermitsSnapshot> | null> {
    try {
      const response = await axios.get(
        `/parking_lots/${lotId}/permits/snapshots`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createParkingPermitSnapshot(
    lotId: number,
    name: string
  ): Promise<ParkingLotParkingPermitsSnapshot | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${lotId}/permits/snapshots/${name}`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async applyParkingPermitSnapshot(
    lotId: number,
    snapshotId: number
  ): Promise<null> {
    try {
      const response = await axios.put(
        `/parking_lots/${lotId}/permits/snapshots/apply/${snapshotId}`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // End Users Permit Grants ///////////////////////////////////////////////////

  async createOrGetEndUserFromEmail(
    lotId: number,
    emailId: string
  ): Promise<EndUser | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${lotId}/end_users/create_or_get`,
        {
          email: emailId,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getAllEndUsersGrantedPermitInLotId(
    lotId: number,
    email?: string | null,
    page?: number,
    itemsPerPage?: number
  ): Promise<PaginatedItems<EndUser> | null> {
    try {
      const filters: any = {};
      if (email != null) {
        filters["email"] = email;
      }
      if (page != null) {
        filters["page"] = page;
      }
      if (itemsPerPage != null) {
        filters["size"] = itemsPerPage;
      }
      const response = await axios.get(`/parking_lots/${lotId}/end_users`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async getEndUserPermitGrantDetails(
    lotId: number,
    endUserId: number
  ): Promise<EndUserFullDetails | null> {
    try {
      const response = await axios.get(
        `/parking_lots/${lotId}/end_users/${endUserId}`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createParkingPermitGrant(
    lotId: number,
    newPermitGrant: EndUserParkingPermitGrantCreate
  ): Promise<EndUserParkingPermitGrant | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${lotId}/end_users/permit_grants`,
        newPermitGrant
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateParkingPermitGrant(
    lotId: number,
    permitGrant: EndUserParkingPermitGrantUpdate
  ): Promise<EndUserParkingPermitGrant | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${lotId}/end_users/permit_grants`,
        permitGrant
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async deleteParkingPermitGrant(
    lotId: number,
    endUserId: number,
    parkingPermitId: number
  ): Promise<boolean | null> {
    try {
      await axios.delete(
        `/parking_lots/${lotId}/end_users/${endUserId}/permit_grants/${parkingPermitId}`
      );
      return true;
    } catch (error) {
      return null;
    }
  },

  // Tenant opearations ////////////////////////////////////////////////////////////

  async getAllTenants(parking_lot_id: number): Promise<Array<Tenant> | null> {
    try {
      const response = await axios.get(`/tenants/${parking_lot_id}`);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createTenant(tenant: TenantCreate): Promise<Tenant | null> {
    try {
      const response = await axios.post("/tenants", tenant);
      return response.data;
    } catch (error) {
      return error?.response?.data?.detail;
    }
  },

  async updateTenant(
    tenantId: number,
    updatedTenant: TenantUpdate
  ): Promise<Tenant | null> {
    try {
      const response = await axios.put(`/tenants/${tenantId}`, updatedTenant);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async deleteTenant(tenantId: number): Promise<boolean> {
    try {
      const response = await axios.delete(`/tenants/${tenantId}`);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  // Private Lot users opearations ////////////////////////////////////////////////////////////

  async getAllPrivateLotUsers(
    parking_lot_id: number
  ): Promise<Array<PrivateLotUser> | null> {
    try {
      const response = await axios.get(`/private_lot_user/${parking_lot_id}`);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createPrivateLotUser(
    privateLotUser: PrivateLotUserCreate
  ): Promise<boolean | null> {
    try {
      const response = await axios.post("/private_lot_user", privateLotUser);
      return response.data.message;
    } catch (error) {
      return error?.response?.data?.detail;
    }
  },

  async updatePrivateLotUser(
    privateLotUserId: number,
    updatedPrivateLotUsers: PrivateLotUserUpdate
  ): Promise<boolean | null> {
    try {
      const response = await axios.put(
        `/private_lot_user/${privateLotUserId}`,
        updatedPrivateLotUsers
      );
      return response.data.message;
    } catch (error) {
      return null;
    }
  },

  async deletePrivateLotUser(privateLotUserId: number): Promise<boolean> {
    try {
      const response = await axios.delete(
        `/private_lot_user/${privateLotUserId}`
      );
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  // Network Video Recorder endpoints //////////////////////////////////////////////////////////

  async getAllNvrs(
    parkingLotId: number
  ): Promise<Array<NetworkVideoRecorders> | null> {
    try {
      const response = await axios.get<Array<NetworkVideoRecorders>>(
        `/parking_lots/${parkingLotId}/nvrs`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createNvr(
    nvr: NetworkVideoRecordersCreate
  ): Promise<NetworkVideoRecorders | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${nvr.parking_lot_id}/nvrs`,
        nvr
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateNvr(
    nvr: NetworkVideoRecordersUpdate
  ): Promise<NetworkVideoRecorders | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${nvr.parking_lot_id}/nvrs/${nvr.id}`,
        nvr
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Service Node endpoints //////////////////////////////////////////////////////////

  async getAllServiceNodes(
    parkingLotId: number
  ): Promise<Array<ServiceNodes> | null> {
    try {
      const response = await axios.get<Array<ServiceNodes>>(
        `/parking_lots/${parkingLotId}/service_nodes`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createServiceNode(
    service_node: ServiceNodesCreate
  ): Promise<ServiceNodes | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${service_node.parking_lot_id}/service_nodes`,
        service_node
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateServiceNode(
    service_node: ServiceNodesUpdate
  ): Promise<ServiceNodes | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${service_node.parking_lot_id}/service_nodes/${service_node.id}`,
        service_node
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Network Devices endpoints //////////////////////////////////////////////////////////

  async getAllNetworkDevices(
    parkingLotId: number
  ): Promise<Array<NetworkDevices> | null> {
    try {
      const response = await axios.get<Array<NetworkDevices>>(
        `/parking_lots/${parkingLotId}/network_devices`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createNetworkDevice(
    network_device: NetworkDevicesCreate
  ): Promise<NetworkDevices | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${network_device.parking_lot_id}/network_devices`,
        network_device
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateNetworkDevice(
    network_device: NetworkDevicesUpdate
  ): Promise<NetworkDevices | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${network_device.parking_lot_id}/network_devices/${network_device.id}`,
        network_device
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Event Subscriptions endpoints //////////////////////////////////////////////////////
  async getAllEventSubscriptions(): Promise<EventSubscriptionsData | null> {
    try {
      const response = await axios.get("/event_subscriptions");
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createNewEventSubscription(
    newEventSubscription: EventSubscriptionCreate
  ): Promise<EventSubscription | null> {
    try {
      const response = await axios.post(
        "/event_subscriptions",
        newEventSubscription
      );
      return response.data;
    } catch (error) {
      // if a similar subscription already exist
      if (error.response.status === 400) {
        throw error;
      }
      return null;
    }
  },

  async updateEventSubscription(
    eventSubscriptionId: number,
    eventSubscription: EventSubscriptionUpdate
  ): Promise<EventSubscription | null> {
    try {
      const response = await axios.put(
        `/event_subscriptions/${eventSubscriptionId}`,
        eventSubscription
      );
      return response.data;
    } catch (error) {
      // if a similar subscription already exist
      if (error.response.status === 400) {
        throw error;
      }
      return null;
    }
  },

  async deleteEventSubscription(
    eventSubscriptionId: number
  ): Promise<boolean | null> {
    try {
      await axios.delete(`/event_subscriptions/${eventSubscriptionId}`);
      return true;
    } catch (error) {
      return null;
    }
  },

  // Third party endpoints //////////////////////////////////////////////////////
  async getSavedEndpoints(): Promise<Array<ThirdPartyEndpoint> | null> {
    try {
      const response = await axios.get("/saved_organization_endpoint");
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createNewThirdPartyEndpoint(
    newThirdPartyEndpoint: ThirdPartyEndpointCreate
  ): Promise<ThirdPartyEndpoint | null> {
    try {
      const response = await axios.post(
        "/saved_organization_endpoint",
        newThirdPartyEndpoint
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async updateThirdPartyEndpoint(
    thirdPartyEndpointId: number,
    thirdPartyEndpoint: ThirdPartyEndpointUpdate
  ): Promise<ThirdPartyEndpoint | null> {
    try {
      const response = await axios.put(
        `/saved_organization_endpoint/${thirdPartyEndpointId}`,
        thirdPartyEndpoint
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async deleteThirdPartyEndpoint(
    thirdPartyEndpointId: number
  ): Promise<boolean | null> {
    try {
      await axios.delete(
        `/saved_organization_endpoint/${thirdPartyEndpointId}`
      );
      return true;
    } catch (error) {
      return null;
    }
  },

  async validateThirdPartyEndpoint(
    thirdPartyEndpoint: ThirdPartyEndpointValidate
  ): Promise<ThirdPartyEndpointValidateResponse | null> {
    try {
      const response = await axios.post(
        "/saved_organization_endpoint/validate",
        thirdPartyEndpoint
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Display Board endpoints //////////////////////////////////////////////////////

  async getAllDisplayBoards(
    parkingLotId: number
  ): Promise<Array<DigitalBoard> | null> {
    try {
      const response = await axios.get(
        `/parking_lots/${parkingLotId}/digital_boards`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },

  async createDigitalBoard(
    parkingLotId: number,
    digitalBoard: DigitalBoardCreate
  ): Promise<DigitalBoard | null> {
    try {
      const response = await axios.post(
        `/parking_lots/${parkingLotId}/digital_boards`,
        digitalBoard
      );
      return response.data;
    } catch (error) {
      if (error.response.status != 200) {
        throw error;
      }
      return null;
    }
  },

  async updateDigitalBoard(
    parkingLotId: number,
    digitalBoard: DigitalBoardUpdate
  ): Promise<DigitalBoard | null> {
    try {
      const response = await axios.put(
        `/parking_lots/${parkingLotId}/digital_boards/${digitalBoard.id}`,
        digitalBoard
      );
      return response.data;
    } catch (error) {
      if (error.response.status != 200) {
        throw error;
      }
      return null;
    }
  },

  async deleteDigitalBoard(
    parkingLotId: number,
    digitalBoardId: number
  ): Promise<boolean | null> {
    try {
      await axios.delete(
        `/parking_lots/${parkingLotId}/digital_boards/${digitalBoardId}`
      );
      return true;
    } catch (error) {
      return null;
    }
  },
};
