




























































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";

import { Camera, EdgeDevice } from "@/api/models";
import ViewStreamHelpPopup from "@/components/ViewStreamHelpPopup.vue";
import CameraForm from "@/components/forms/CameraForm.vue";
import api from "@/api/api";
import { map } from "lodash";

interface CameraHeaders {
  text: string;
  value: string;
}

export default Vue.extend({
  name: "CamerasTable",

  components: {
    CameraForm,
    ViewStreamHelpPopup,
  },

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
    garbledImageDetectionEnabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showCameraForm: false,
    selectedCameraDetails: null as Camera | null,
    isLoading: false,
    frameDownload: {
      isDownloading: false,
      numCameraFramesDownloaded: 0,
    },
    cameras: {
      initialHeaders: [
        { text: "Camera ID", value: "id" },
        { text: "Name", value: "name" },
      ],
      headers: [
        { text: "Stream URL", value: "stream_url", sortable: false },
        { text: "Server / Edge Device", value: "server_id" },
        { text: "Comment", value: "comment" },
        { text: "Status", value: "is_stream_unreadable" },
      ],
      lastHeaders: [
        { text: "Actions", value: "camera_actions", sortable: false },
      ],
      filteredHeaders: [] as Array<CameraHeaders>,
      selectedHeaders: ["is_stream_unreadable"] as Array<string>,
      data: [] as Array<Camera>,
    },
    showViewStreamHelpDialog: false,
    cameraActions: {
      show: false,
      checkingAlertType: false,
      isCameraTiltAlert: false,
      isRefinementWorking: false,
    },
    cameraDelete: false,
    showConfirmDisableDialog: false,
    waitingForCameraToBeDisabled: false,

    // When the toggle switch on any camera is changed, then this is set to setInterval
    // and camera details will autorefresh automatically.
    cameraDetailsAutoRefreshIntervalObj: null as number | null,

    newCamera: {
      show: false,
      camera: null as Camera | null,
      receiveDataEndpoint: "",
      heartbeatEndpoint: "",
    },
  }),

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "isTechnician"]),
    ...mapGetters("data", ["getCurrentParkingLotData"]),
  },

  mounted() {
    if (this.isSuperAdmin) {
      this.cameras.headers.push({
        text: "Pending Alert",
        value: "has_pending_alert",
      });
      this.cameras.selectedHeaders.push("server_id");
    } else {
      this.cameras.headers = this.cameras.headers.filter(
        (header) =>
          header.value !== "server_id" && header.value !== "lpr_edge_device_id"
      );
    }
    if (
      this.getCurrentParkingLotData &&
      this.getCurrentParkingLotData?.is_lpr_feature_enabled
    ) {
      // find index of server_id
      const index = this.cameras.headers.findIndex(
        (header) => header.value === "server_id"
      );
      // add entry after server_id
      this.cameras.headers.splice(index + 1, 0, {
        text: "LPR Edge Device",
        value: "lpr_edge_device_id",
      });
      this.cameras.selectedHeaders.push("lpr_edge_device_id");
    }
    this.cameras.filteredHeaders = [
      ...this.cameras.initialHeaders,
      ...this.cameras.headers,
      ...this.cameras.lastHeaders,
    ];
    this.getCamerasData();
  },

  destroyed() {
    if (this.cameraDetailsAutoRefreshIntervalObj) {
      clearInterval(this.cameraDetailsAutoRefreshIntervalObj);
    }
  },

  methods: {
    async getCamerasData() {
      console.log("Fetching cameras info");
      this.isLoading = true;
      let cameras = await api.getAllCameras(this.parkingLotId);
      if (cameras !== null) {
        this.cameras.data = cameras;
      } else {
        console.log(
          "Unable to load list of cameras for lot id",
          this.parkingLotId
        );
      }
      this.showCameraColumns();
      this.isLoading = false;
    },
    showCameraDetailsInForm(cameraOrId: number | Camera) {
      let cameraId = 0;
      if (typeof cameraOrId === "object") {
        cameraId = cameraOrId.id;
      } else {
        cameraId = cameraOrId;
      }
      this.selectedCameraDetails =
        this.cameras.data.find((c) => c.id === cameraId) ?? null;
      console.log(
        "Showing camera details for id",
        cameraId,
        this.selectedCameraDetails
      );
      if (this.selectedCameraDetails) {
        this.showCameraForm = true;
      }
    },
    async refreshLprLogs(camera_id: number) {
      await this.getCamerasData();
      this.showCameraDetailsInForm(camera_id);
    },
    closeCameraForm() {
      this.selectedCameraDetails = null;
      this.showCameraForm = false;
    },
    async downloadFrame(cameraId: number, cameraName: string) {
      try {
        const url = await api.downloadFrame(this.parkingLotId, cameraId);
        if (url) {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `frame_${cameraName.replace(" ", "_")}.jpg`
          );
          document.body.appendChild(link);
          link.click();
        } else {
          this.$dialog.message.error(
            `Read Error, unable to read from camera "${cameraName}"`,
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      } catch (error) {
        if (error.response.status === 403) {
          this.$dialog.message.warning(
            "Unstable camera stream connection, unable to read frame.<br> Please try again later.",
            {
              position: "top-right",
              timeout: 5000,
            }
          );
        }
      }
    },
    async downloadAllCameraFrames() {
      this.frameDownload.numCameraFramesDownloaded = 0;
      this.frameDownload.isDownloading = true;
      for (const [i, camera] of this.cameras.data.entries()) {
        await this.downloadFrame(camera.id, camera.name);
        this.frameDownload.numCameraFramesDownloaded += 1;
      }
      this.frameDownload.isDownloading = false;
    },
    async setCameraRefinement() {
      if (
        this.cameraActions.isRefinementWorking &&
        this.selectedCameraDetails &&
        this.parkingLotId
      ) {
        let camera = null;
        try {
          camera = await api.setCameraRefinement(
            this.parkingLotId,
            this.selectedCameraDetails.id
          );
        } catch (error) {
          this.$dialog.message.error(error.response.data.detail, {
            position: "top-right",
            timeout: 3000,
          });
          this.resetCameraActions();
          return;
        }
        if (camera) {
          console.log(
            "Camera Refinement has been started successfully.",
            camera
          );
          this.$dialog.message.info(
            "Camera Refinement has been started successfully.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
          this.resetCameraActions();
          this.getCamerasData();
        } else {
          console.log("Failed to start camera refinement.");
          this.$dialog.message.error(
            "Error, Failed to start camera refinement. Please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      }
    },
    async checkIfCameraTiltAlert(alertId: number) {
      this.cameraActions.checkingAlertType = true;
      if (alertId) {
        let alert = await api.getAlert(alertId);
        // if alert_type_id is set and it is a one of the Tilt alerts (Tilt alert type have ids 7 and 10) then do not allow camera refinement
        if (
          alert &&
          alert.alert_type_id &&
          [7, 10].includes(alert.alert_type_id)
        ) {
          this.cameraActions.isCameraTiltAlert = true;
          this.cameraActions.checkingAlertType = false;
          return;
        }
        if (
          alert &&
          alert.title &&
          alert.title.toLowerCase().includes("tilt")
        ) {
          this.cameraActions.isCameraTiltAlert = true;
          this.cameraActions.checkingAlertType = false;
          return;
        }
      }
      this.cameraActions.isCameraTiltAlert = false;
      this.cameraActions.checkingAlertType = false;
    },
    openAlert(camera: Camera | undefined) {
      if (!this.isSuperAdmin || !camera || !camera.has_pending_alert) return;
      const routeData = this.$router.resolve({
        name: "Alerts",
        query: { alert_id: String(camera.map_updated_alert_id) },
      });
      window.open(routeData.href, "_blank");
    },
    openStream(streamUrl: string) {
      navigator.clipboard.writeText(streamUrl);
      if (streamUrl.startsWith("http")) {
        // Only open the youtube streams automatically in a new tab.
        window.open(streamUrl, "_blank");
      } else {
        // Automatic opening of stream is disabled for rtsp, since it doesn't work
        // reliably on all browsers & systems. So we ask the user to launch VLC
        // themselves from the help popup dialog.
        window.open(streamUrl, "_parent");
        this.showViewStreamHelpDialog = true;
      }
    },
    showViewStreamHelp(value: boolean) {
      this.showViewStreamHelpDialog = value;
    },
    showCameraActions(camera: Camera) {
      this.cameraActions.show = true;
      this.selectedCameraDetails = camera;
    },
    showConfirmDisable(camera: Camera) {
      this.selectedCameraDetails = camera;
      if (!camera.is_active) {
        this.showConfirmDisableDialog = true;
        this.waitingForCameraToBeDisabled = false;
      } else {
        this.updateIsActive();
      }
    },
    async deleteCamera() {
      this.cameraDelete = false;
      if (this.selectedCameraDetails) {
        let deleteSuccessful = await api.deleteCamera(
          this.parkingLotId,
          this.selectedCameraDetails.id
        );
        if (deleteSuccessful) {
          this.getCamerasData();
        } else {
          console.error(
            "Unable to delete camera",
            this.selectedCameraDetails.id
          );
        }
      }
    },
    deleteCameraDialog(camera: Camera) {
      this.cameraDelete = true;
      this.selectedCameraDetails = camera;
    },
    cancelDisableIsActive() {
      if (!this.waitingForCameraToBeDisabled) {
        if (this.selectedCameraDetails) {
          this.selectedCameraDetails.is_active =
            !this.selectedCameraDetails?.is_active;
        }
        this.showConfirmDisableDialog = false;
        this.waitingForCameraToBeDisabled = false;
      }
    },
    async updateIsActive() {
      if (this.selectedCameraDetails) {
        console.log(
          "Changing isActive to:",
          !this.selectedCameraDetails?.is_active
        );
        let cameraData = {
          id: this.selectedCameraDetails.id,
          parking_lot_id: this.parkingLotId,

          name: this.selectedCameraDetails.name,
          stream_url: this.selectedCameraDetails.stream_url,
          public_stream_url: this.selectedCameraDetails.public_stream_url,
          gps_coordinates: this.selectedCameraDetails.gps_coordinates,
          direction_point: this.selectedCameraDetails.direction_point,
          calibration_params: this.selectedCameraDetails.calibration_params,

          sampling_interval_secs:
            this.selectedCameraDetails.sampling_interval_secs,
          refined_frame_path: this.selectedCameraDetails.refined_frame_path,
          is_inference_processing_method:
            this.selectedCameraDetails.is_inference_processing_method,
          server_id: this.selectedCameraDetails.server_id,
          edge_device_id: this.selectedCameraDetails.edge_device_id,
          lpr_edge_device_id: this.selectedCameraDetails.lpr_edge_device_id,
          is_refinement_working:
            this.selectedCameraDetails.is_refinement_working,

          camera_offline_alert_delay_threshold_minutes:
            this.selectedCameraDetails
              .camera_offline_alert_delay_threshold_minutes,
          comment: this.selectedCameraDetails.comment,

          is_lpr_camera_type: this.selectedCameraDetails.is_lpr_camera_type,
          is_lpr_status_check_enabled:
            this.selectedCameraDetails.is_lpr_status_check_enabled,
          is_direction_detected_from_lpr:
            this.selectedCameraDetails.is_direction_detected_from_lpr,
          lpr_direction: this.selectedCameraDetails.lpr_direction,
          is_lot_boundary_lpr_camera:
            this.selectedCameraDetails.is_lot_boundary_lpr_camera,
          lpr_url: this.selectedCameraDetails.lpr_url,
          save_lpr_events_only_for_vehicle_facing_direction: "both",
          save_exit_lpr_events_only_for_vehicle_facing_direction: "both",
          crop_anpr_matching_image_using_inference:
            this.selectedCameraDetails.crop_anpr_matching_image_using_inference,

          is_camera_garbled_image_detection_feature_enabled:
            this.selectedCameraDetails
              .is_camera_garbled_image_detection_feature_enabled,

          untracked_zone_id: this.selectedCameraDetails.untracked_zone_id,
          adjacent_zone_id: this.selectedCameraDetails.adjacent_zone_id,
          lpr_zone_id: this.selectedCameraDetails.lpr_zone_id,
          fov_direction: this.selectedCameraDetails.fov_direction,
          lpr_log: this.selectedCameraDetails.lpr_log,
        };
        let updatedCamera = {
          ...cameraData,
          is_active: this.selectedCameraDetails?.is_active,
        };
        let errorMessage = "Error, unable to start camera.";
        try {
          this.waitingForCameraToBeDisabled = true;
          setTimeout(() => {
            this.showConfirmDisableDialog = false;
            this.waitingForCameraToBeDisabled = false;
          }, process.env.VUE_APP_CAMERA_RESTART_WAIT_TIME_SECONDS * 1000);
          let responseData = await api.updateCamera(updatedCamera);
          if (responseData) {
            this.$emit("update:cameraData", responseData);
            let message = responseData.is_active
              ? "Camera started successfully"
              : "Camera stopped successfully";
            this.$dialog.message.info(message, {
              position: "top-right",
              timeout: 3000,
            });
            await this.getCamerasData();
            this.selectedCameraDetails.is_active = responseData.is_active;
            this.selectedCameraDetails.is_stream_unreadable =
              responseData.is_stream_unreadable;
            this.startCameraDetailsAutoRefresh();
          } else {
            this.$dialog.message.error(errorMessage, {
              position: "top-right",
              timeout: 3000,
            });
          }
        } catch (error) {
          console.log("Got error", error);
          if (error.response.status === 503) {
            errorMessage = error.response.data.detail;
            this.$dialog.message.error(errorMessage, {
              position: "top-right",
              timeout: 3000,
            });
            if (this.selectedCameraDetails) {
              this.selectedCameraDetails.is_active = error.response.is_active;
            }
          }
        }
      }
    },
    startCameraDetailsAutoRefresh() {
      if (this.cameraDetailsAutoRefreshIntervalObj === null) {
        this.cameraDetailsAutoRefreshIntervalObj = setInterval(() => {
          console.log("Refreshing Camera Data...");
          this.getCamerasData();
        }, 10 * 1000);
      }
    },
    showCameraColumns() {
      this.cameras.filteredHeaders = [];
      this.cameras.filteredHeaders = [
        ...this.cameras.initialHeaders,
        ...this.cameras.headers.filter((header) =>
          this.cameras.selectedHeaders.includes(header.value)
        ),
        ...this.cameras.lastHeaders,
      ];
    },

    resetCameraActions() {
      this.selectedCameraDetails = null;
      this.cameraActions.show = false;
      this.cameraActions.isCameraTiltAlert = false;
      this.cameraActions.checkingAlertType = false;
      this.cameraActions.isRefinementWorking = false;
    },

    copyToClipboard(val: string) {
      navigator.clipboard.writeText(val);
    },

    showLPREndpoints(camera: Camera) {
      this.newCamera.show = true;
      this.newCamera.camera = camera;
      this.newCamera.receiveDataEndpoint = `${process.env.VUE_APP_API_URL}/lpr/receive_data/lot_${camera.parking_lot_id}/camera_${camera.id}`;
      this.newCamera.heartbeatEndpoint = `${process.env.VUE_APP_API_URL}/lpr/heartbeat/lot_${camera.parking_lot_id}/camera_${camera.id}`;
    },

    customCamerasSort(
      items: Array<any>,
      sortBy: Array<string>,
      sortDesc: Array<boolean>
    ) {
      let sort_by = sortBy[0];
      let sort_desc = sortDesc[0];
      console.log(
        "Sorting by",
        sort_by,
        "in descending order",
        sort_desc,
        items
      );
      // Check if the sorting should be custom for a specific header
      if (sort_by === "is_stream_unreadable") {
        return this.customSortByCameraStatus(items, sort_desc);
      } else if (sort_by === "server_id") {
        return this.customServerEdgeDeviceSort(items, sort_desc);
      } else {
        // If not, use default sorting
        return this.defaultSort(items, sortBy, sortDesc);
      }
    },
    defaultSort(
      items: Array<any>,
      sortBy: Array<string>,
      sortDesc: Array<boolean>
    ) {
      // Default sorting logic
      return items.sort((a, b) => {
        const sortA = a[sortBy[0]];
        const sortB = b[sortBy[0]];
        if (sortDesc[0]) {
          if (typeof sortA === "string" && typeof sortB === "string") {
            return sortA.localeCompare(sortB);
          } else {
            return sortA > sortB ? -1 : sortA < sortB ? 1 : 0;
          }
        } else {
          if (typeof sortA === "string" && typeof sortB === "string") {
            return sortB.localeCompare(sortA);
          } else {
            return sortA > sortB ? 1 : sortA < sortB ? -1 : 0;
          }
        }
      });
    },
    customServerEdgeDeviceSort(items: Array<any>, sortDesc: boolean) {
      // Custom sorting logic for server_id column
      return items.sort((a, b) => {
        let sortA = a.server_id
          ? a.server_name
          : a.edge_device_id
          ? a.edge_device_name
          : "";
        let sortB = b.server_id
          ? b.server_name
          : b.edge_device_id
          ? b.edge_device_name
          : "";
        if (sortDesc) {
          return sortA.localeCompare(sortB);
        } else {
          return sortB.localeCompare(sortA);
        }
      });
    },
    customSortByCameraStatus(items: Array<any>, sortDesc: boolean) {
      // Custom sorting logic for name column
      return items.sort((a, b) => {
        let sortA = 0;
        let sortB = 0;
        if (a.is_active) {
          if (a.edge_device && a.edge_device.is_device_offline) {
            sortA = 2;
          } else if (a.is_stream_unreadable) {
            sortA = 3;
          } else {
            sortA = 1;
          }
        } else {
          sortA = 0;
        }
        if (b.is_active) {
          if (b.edge_device && b.edge_device.is_device_offline) {
            sortB = 2;
          } else if (b.is_stream_unreadable) {
            sortB = 3;
          } else {
            sortB = 1;
          }
        } else {
          sortB = 0;
        }
        if (sortDesc) {
          return sortA > sortB ? -1 : sortA < sortB ? 1 : 0;
        } else {
          return sortA > sortB ? 1 : sortA < sortB ? -1 : 0;
        }
      });
    },
    getLprStatusColor(item: Camera) {
      if (!item || !item.is_active) return "grey";

      if (
        !(
          item.is_lpr_camera_type == "platerecognizer_lpr" &&
          item.lpr_edge_device_id != null
        )
      ) {
        if (!item.is_lpr_status_check_enabled) return "orange";
      }
      if (item.is_lpr_unreadable) {
        return "red";
      } else {
        return "green";
      }
    },
    getLprStatus(item: Camera) {
      if (
        !(
          item.is_lpr_camera_type == "platerecognizer_lpr" &&
          item.lpr_edge_device_id != null
        )
      ) {
        if (!item.is_lpr_status_check_enabled) return "Status Unavailable";
      }
      if (item.is_lpr_unreadable) {
        return "Camera is Down";
      } else {
        return "Camera is Up";
      }
    },
  },

  watch: {
    /**
     * Reset the CameraForm whenever its dialog is hidden/closed.
     */
    showCameraForm(showingForm) {
      if (!showingForm) {
        (this.$refs.cameraForm as any).resetForm();
      }
    },
    cameraActions(value: boolean) {
      if (
        value &&
        this.selectedCameraDetails &&
        this.selectedCameraDetails.map_updated_alert_id
      ) {
        this.checkIfCameraTiltAlert(
          this.selectedCameraDetails.map_updated_alert_id
        );
      } else {
        this.resetCameraActions();
      }
    },
    "cameraActions.show"(value: boolean) {
      if (!value) {
        this.resetCameraActions();
      }
    },
  },
});
