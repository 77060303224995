




























































































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

import api from "@/api/api";
import { ParkingLot } from "@/api/models";

import TrendsChart from "@/components/TrendsChart.vue";
import AccuracyTrackingChart from "@/components/AccuracyTrackingChart.vue";
import TenantOccupancyChart from "@/components/TenantOccupancyChart.vue";
import AppUsageChart from "@/components/AppUsageChart.vue";

export default Vue.extend({
  name: "LotTrends",

  components: {
    TrendsChart,
    AccuracyTrackingChart,
    TenantOccupancyChart,
    AppUsageChart,
  },

  data() {
    return {
      isLoading: false,
      lotId: 0,
      breadcrumbItems: [
        {
          text: "Reports",
          disabled: false,
        },
      ],
      tab: "occupancy",
      parkingLotData: null as ParkingLot | null,
      parkingLotName: null as string | null,
      occupanciesChartType: null as string | null,
      reports: {
        selected: "occupancy",
        items: [
          { label: "Occupancy Report", value: "occupancy" },
          { label: "Dwell Time Report", value: "dwell_time" },
          { label: "Turnover Report", value: "turnover" },
          { label: "Accuracy Report", value: "accuracy" },
          { label: "App Usage Report", value: "app_usage" },
        ],
      },
      isPrivateLot: false,
    };
  },

  created() {
    this.lotId = Number(this.$route.params.lotId);
  },
  async mounted() {
    // check if Logged in or has url token set in local storage, if not redirect to login
    if (!this.isLoggedIn && localStorage.getItem("url_token") == null) {
      this.$router.push({ name: "Login" });
    }

    const reportType = this.$route.query.report;
    if (reportType && typeof reportType == "string") {
      this.reports.selected = reportType;
    }

    const plot = this.$route.query.plot;
    if (plot) {
      this.occupanciesChartType = plot as string;
      if (plot == "dwell") {
        this.reports.selected = "dwell_time";
      } else if (plot == "turnover") {
        this.reports.selected = "turnover";
      }
    }
    this.setBreadcrumbItems();

    await this.fetchParkingLotData();

    if (!this.isSuperAdmin) {
      this.reports.items = this.reports.items.filter(
        (report) => report.value != "accuracy"
      );
    }
  },

  computed: {
    ...mapGetters("user", [
      "isSuperAdmin",
      "isAdmin",
      "isLoggedIn",
      "hasAccessLevelDashboardMonitoring",
    ]),
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),

    async fetchParkingLotData() {
      this.isLoading = true;
      if (this.lotId) {
        this.parkingLotData = await api.getParkingLot(this.lotId);
        this.initCurrentParkingLotData(this.parkingLotData);
        if (this.parkingLotData) {
          this.isPrivateLot = this.parkingLotData.is_private;
          if (this.isPrivateLot) {
            this.reports.items.push({
              label: "Park my Car Report",
              value: "tenant",
            });
          } else {
            this.reports.items = this.reports.items.filter(
              (report) => report.value !== "tenant"
            );
          }
          if (
            this.parkingLotData.is_revenue_feature_enabled &&
            (this.isSuperAdmin || this.isAdmin)
          ) {
            this.reports.items.push({
              label: "Revenue Estimator",
              value: "revenue",
            });
          }
          if (this.isSuperAdmin || this.isAdmin) {
            this.reports.items.push({
              label: "Violations Report",
              value: "violations",
            });
          }
          this.setBreadcrumbItems();
        } else {
          let parkingLotName = await api.getParkingLotName(this.lotId);
          if (parkingLotName) {
            this.parkingLotName = parkingLotName;
          } else {
            this.parkingLotName = "";
          }

          if (localStorage.getItem("url_token") != null) {
            this.reports.items.push({
              label: "Park my Car Report",
              value: "tenant",
            });
          }
        }
      }
      this.isLoading = false;
    },

    openPage(page: string) {
      if (page === "Reports") {
        window.open(
          `${process.env.VUE_APP_3_BASE_URL_PATH}/dashboard/reports/${this.lotId}`,
          "_self"
        );
      } else {
        this.$router.push({ name: "LotDashboard" });
      }
    },

    setBreadcrumbItems() {
      this.breadcrumbItems = [];
      this.breadcrumbItems.push({
        text: this.parkingLotData ? this.parkingLotData.name : "DigiMap",
        disabled: false,
      });
      this.breadcrumbItems.push({
        text: "Reports",
        disabled: !this.isLoggedIn,
      });
      const reportName =
        this.reports.selected == "revenue"
          ? "Revenue Estimator"
          : this.reports.selected == "occupancy"
          ? "Occupancy Report"
          : this.reports.selected == "dwell_time"
          ? "Dwell Time Report"
          : this.reports.selected == "turnover"
          ? "Turnover Report"
          : this.reports.selected == "accuracy"
          ? "Accuracy Report"
          : this.reports.selected == "app_usage"
          ? "App Usage Report"
          : this.reports.selected == "tenant"
          ? "Park my Car Report"
          : "";
      this.breadcrumbItems.push({
        text: reportName,
        disabled: true,
      });
    },

    openAdminNextReports(lotId: number) {
      window.open(
        `${process.env.VUE_APP_3_BASE_URL_PATH}/dashboard/reports/revenue/${lotId}`,
        "_self"
      );
    },

    openAdminNextViolationsReport(lotId: number) {
      window.open(
        `${process.env.VUE_APP_3_BASE_URL_PATH}/dashboard/reports/violations/${lotId}`,
        "_self"
      );
    },

    openAdminNextOccupancyReport(lotId: number) {
      window.open(
        `${process.env.VUE_APP_3_BASE_URL_PATH}/dashboard/reports/occupancy/${lotId}`,
        "_self"
      );
    },

    checkIfRevenueEstimator() {
      this.setBreadcrumbItems();
      if (this.reports.selected == "revenue") {
        // use openAdminNextReports Vue prototype
        this.openAdminNextReports(this.lotId);
      } else if (this.reports.selected == "violations") {
        this.openAdminNextViolationsReport(this.lotId);
      } else if (this.reports.selected == "occupancy") {
        this.openAdminNextOccupancyReport(this.lotId);
      }
    },
  },

  watch: {
    isSuperAdmin(newValue) {
      if (newValue) {
        const accuracy = this.reports.items.find((r) => r.value == "accuracy");
        if (!accuracy) {
          this.reports.items.splice(3, 0, {
            label: "Accuracy Report",
            value: "accuracy",
          });
        }
        if (
          !this.reports.items.find((r) => r.value == "revenue") &&
          this.parkingLotData?.is_revenue_feature_enabled
        ) {
          this.reports.items.push({
            label: "Revenue Estimator",
            value: "revenue",
          });
        }
        if (!this.reports.items.find((r) => r.value == "violations")) {
          this.reports.items.push({
            label: "Violations Report",
            value: "violations",
          });
        }
      } else {
        this.reports.items = this.reports.items.filter(
          (report) => report.value != "accuracy"
        );
      }
    },
  },
});
