

































































































































































































































































































































































































import Vue from "vue";
import api from "@/api/api";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import Chart, { ChartItem, ChartConfiguration } from "chart.js/auto";
import {
  ParkingLot,
  ParkingLotZoneOccupancyCount,
  ParkingLotDwellTime,
  ParkingLotTurnover,
  ParkingPermit,
} from "@/api/models";

export interface BarChartDataset {
  label: string;
  data: Array<number>;
  total_data: Array<number>;
  borderColor: string;
  backgroundColor: string;
  barPercentage: number;
  showActualValues: boolean;
  showLabels: boolean;
  selectedChartType: string;
  ignoreUnmappedSpots: boolean;
}

export interface LineChartDataset {
  label: string;
  data: Array<number>;
  borderColor: string;
  backgroundColor: string;
  fill: boolean;
  showActualValues: boolean;
  showLabels: boolean;
  selectedChartType: string;
  ignoreUnmappedSpots: boolean;
}

export default Vue.extend({
  name: "TrendsChart",
  props: {
    lotId: {
      type: Number,
      required: true,
    },
    parkingLotData: {
      type: Object as () => ParkingLot,
      required: true,
    },
    occupancyChartType: {
      type: String,
      required: false,
    },
    occupancyReportType: {
      type: String,
      required: true,
    },
    parkingLotName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      occupanciesChartType: [
        { label: "Occupancies", value: "occupancy" },
        { label: "Dwell Time", value: "dwell_time" },
        { label: "Parking Turnover", value: "turnover" },
      ],
      selectedChartType: "occupancy",
      isLprZoneSelected: false,

      occupanciesChart: null as Chart<"bar"> | null,
      dwellTimeChart: null as Chart<"line"> | null,
      parkingTurnoverChart: null as Chart<"line"> | null,

      occupanciesData: null as Array<ParkingLotZoneOccupancyCount> | null,
      dwellTimeData: null as Array<ParkingLotDwellTime> | null,
      parkingTurnoverData: null as Array<ParkingLotTurnover> | null,

      chart: {
        isLoading: false,
        showActualValues: false,
        showActualValuesToggleDisabled: false,
        ignoreUnmappedSpots: false,
        showStartDate: false,
        showStartTime: false,
        startDate: "",
        startTime: "00:00",
        showEndDate: false,
        showEndTime: false,
        endDate: "",
        endTime: "23:59",
        area: [
          { header: "Spot Tracking Zones" },
          { label: "Full Lot", value: -1 },
          { label: "Zone Wise", value: 0 },
        ] as Array<any>,
        selectedArea: -1,
        granularity: [
          { label: "5 Minutes", value: 60 * 5 },
          { label: "15 Minutes", value: 60 * 15 },
          { label: "30 Minutes", value: 60 * 30 },
          { label: "Hourly", value: 60 * 60 },
          { label: "Daily", value: 60 * 60 * 24 },
          { label: "Weekly", value: 60 * 60 * 24 * 7 },
          // { label: "Monthly", value: 60 * 5 * 24 * 30 },
        ],
        selectedGranularity: 60 * 60,
        duration: [
          { label: "Today", value: 0 },
          { label: "1 Week", value: 1 },
          { label: "1 Month", value: 2 },
          { label: "Last Month", value: 3 },
          { label: "Custom", value: 4 },
        ],
        selectedDuration: 0,
        selectedSpotPermitType: null as number | null,
        permitTypes: [] as Array<ParkingPermit>,

        type: "bar",
        data: {
          labels: [] as Array<string>,
          datasets: [] as Array<BarChartDataset | LineChartDataset>,
        },
        occupanciesData: {
          labels: [] as Array<string>,
          datasets: [] as Array<BarChartDataset>,
        },
        dwellTimeData: {
          labels: [] as Array<string>,
          datasets: [] as Array<LineChartDataset>,
        },
        parkingTurnoverData: {
          labels: [] as Array<string>,
          datasets: [] as Array<LineChartDataset>,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              stacked: false,
              title: {
                display: true,
                text: "% Occupied",
              },
              grace: 1,
            },
            x: {
              title: {
                display: true,
                text: "Date and Time",
              },
              ticks: {
                color: ["rgb(0,0,0)", "rgb(0,0,0)", "rgb(0,0,0)"],
                padding: 2,
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Zone Occupancies",
            },
            legend: {
              position: "top",
            },
            tooltip: {
              intersect: false,
              mode: "index",
              titleColor: "#FFFFFF",
              titleFont: { weight: "bold" },
              callbacks: {
                label: function (context: any) {
                  let index = context.dataIndex;
                  let value = context.dataset.data[index];
                  let label = context.dataset.showLabels
                    ? ` ${context.dataset.label}:`
                    : "";
                  let showActualValues = context.dataset.showActualValues;
                  let ignoreUnmappedSpots = context.dataset.ignoreUnmappedSpots;
                  let chartType = context.dataset.selectedChartType;
                  if (chartType == "occupancy") {
                    if (showActualValues) {
                      return `${label} ${value} Spots Occupied`;
                    } else {
                      return `${label} ${Math.round(value)}% Occupied`;
                    }
                  } else if (chartType == "dwell_time") {
                    return `${label} ${value}${showActualValues ? "" : "%"}`;
                  } else if (chartType == "turnover") {
                    if (showActualValues) {
                      return `Parking Sessions: ${value}`;
                    } else {
                      return `${label} ${value}`;
                    }
                  }
                },
              },
            },
          },
        },
        ignoreParkingSessionsUnder: 60,
      },
      isExportingData: false,

      IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED:
        process.env.VUE_APP_IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED,
    };
  },
  mounted() {
    this.setFiltersFromURLParams();

    if (this.selectedChartType === "occupancy") {
      this.chart.startDate = this.todaysDate;
      this.chart.endDate = this.todaysDate;
    } else {
      this.chart.startDate = this.yesterdaysDate;
      this.chart.endDate = this.yesterdaysDate;
    }

    this.initializeChart();
    // this.fetchData();

    this.selectedChartType = this.occupancyReportType;
    this.updateChartData();

    if (
      this.occupancyChartType == "dwell" &&
      this.selectedChartType != "dwell_time"
    ) {
      this.selectedChartType = "dwell_time";
      this.updateChartData();
    }

    const plot = this.$route.query.plot;
    if (plot) {
      if (plot == "dwell" && this.selectedChartType != "dwell_time") {
        this.selectedChartType = "dwell_time";
        this.updateChartData();
      }
    }
  },
  methods: {
    setFiltersFromURLParams() {
      const area = Number(this.$route.query.area);
      if (area && typeof area == "number") {
        this.chart.selectedArea = area;
      }
      const duration = Number(this.$route.query.duration);
      if (duration && typeof duration == "number") {
        this.chart.selectedDuration = duration;
        this.setChartDates();
        this.setChartGranularity();
      }
      const granularity = Number(this.$route.query.granularity);
      if (granularity && typeof granularity == "number") {
        this.chart.selectedGranularity = granularity;
      }
    },
    onSelectedAreaFilterChange() {
      this.chart.ignoreUnmappedSpots = false;
      let selectedZone = this.parkingLotData.parking_zones.find(
        (zone) => zone.id === this.chart.selectedArea
      );

      // Turnover chart percentage does not work with pick up and drop off zones
      // so enable actual count values if a pick up drop off zone is selected.
      if (this.selectedChartType === "turnover") {
        if (selectedZone && selectedZone.zone_type === "time_limited_zone") {
          this.chart.showActualValues = true;
          this.chart.showActualValuesToggleDisabled = true;
        } else {
          this.chart.showActualValuesToggleDisabled = false;
        }
      }

      if (selectedZone && selectedZone.zone_type === "lpr_counter_zone") {
        this.isLprZoneSelected = true;
      } else {
        this.isLprZoneSelected = false;
      }
    },
    openAdminNextOccupancyChart() {
      window.open(
        `${process.env.VUE_APP_3_BASE_URL_PATH}/dashboard/reports/occupancy/${this.lotId}?area=${this.chart.selectedArea}&duration=${this.chart.selectedDuration}&granularity=${this.chart.selectedGranularity}`,
        "_self"
      );
    },
    fetchData(callapi = true) {
      this.chart.isLoading = true;

      if (this.selectedChartType === "occupancy") {
        this.chart.type = "bar";
        this.chart.options.scales.y.stacked = false;
        this.chart.occupanciesData.labels = [];
        this.chart.occupanciesData.datasets = [];
        this.fetchOccupanciesChartData(callapi);
      } else if (this.selectedChartType === "dwell_time") {
        this.chart.type = "line";
        this.chart.options.scales.y.stacked = true;
        this.chart.dwellTimeData.labels = [];
        this.chart.dwellTimeData.datasets = [];
        this.chart.options.scales.y.title.text = "% of Parking Sessions";
        this.fetchDwellTimeChartData(callapi);
      } else if (this.selectedChartType === "turnover") {
        this.chart.type = "line";
        this.chart.options.scales.y.stacked = false;
        this.chart.parkingTurnoverData.labels = [];
        this.chart.parkingTurnoverData.datasets = [];
        this.chart.options.scales.y.title.text = "Parking Turnover";
        this.fetchParkingTurnoverChartData(callapi);
      }
      this.setArea();
      this.setChartGranularity(true);
      this.fetchPermitTypes();
    },
    initializeChart() {
      this.setChartDates();

      if (this.occupanciesChart) this.occupanciesChart.destroy();
      const occ_ctx = document.getElementById("occupancies-chart") as ChartItem;
      this.occupanciesChart = new Chart(
        occ_ctx,
        this.chart as ChartConfiguration<"bar">
      );

      if (this.dwellTimeChart) this.dwellTimeChart.destroy();
      const dwell_ctx = document.getElementById(
        "dwell-time-chart"
      ) as ChartItem;
      this.dwellTimeChart = new Chart(
        dwell_ctx,
        this.chart as ChartConfiguration<"line">
      );

      if (this.parkingTurnoverChart) this.parkingTurnoverChart.destroy();
      const turnover_ctx = document.getElementById(
        "parking-turnover-chart"
      ) as ChartItem;
      this.parkingTurnoverChart = new Chart(
        turnover_ctx,
        this.chart as ChartConfiguration<"line">
      );
    },
    updateChartData() {
      this.chart.ignoreUnmappedSpots = false;
      if (this.selectedChartType === "occupancy") {
        this.chart.duration[0].label = "Today";
      } else {
        this.chart.duration[0].label = "Yesterday";
      }
      if (this.chart.selectedDuration == 0) {
        if (this.selectedChartType === "occupancy") {
          this.chart.startDate = this.todaysDate;
          this.chart.endDate = this.todaysDate;
        } else {
          this.chart.startDate = this.yesterdaysDate;
          this.chart.endDate = this.yesterdaysDate;
        }
      } else {
        if (this.selectedChartType !== "occupancy") {
          if (this.chart.startDate == this.todaysDate) {
            this.chart.startDate = this.yesterdaysDate;
          }
          if (this.chart.endDate == this.todaysDate) {
            this.chart.endDate = this.yesterdaysDate;
          }
        }
      }

      this.chart.data.labels = [];
      this.chart.data.datasets = [];
      if (this.selectedChartType === "occupancy") {
        this.chart.type = "bar";
        if (this.chart.occupanciesData.labels.length <= 0) {
          this.fetchData();
          return;
        }
        this.chart.data.labels = this.chart.occupanciesData.labels;
        this.chart.data.datasets = this.chart.occupanciesData.datasets;
      } else if (this.selectedChartType === "dwell_time") {
        this.chart.type = "line";
        if (this.chart.dwellTimeData.labels.length <= 0) {
          this.fetchData();
          return;
        }
        this.chart.data.labels = this.chart.dwellTimeData.labels;
        this.chart.data.datasets = this.chart.dwellTimeData.datasets;
      } else if (this.selectedChartType === "turnover") {
        this.chart.type = "line";
        if (this.chart.parkingTurnoverData.labels.length <= 0) {
          this.fetchData();
          return;
        }
        this.chart.data.labels = this.chart.parkingTurnoverData.labels;
        this.chart.data.datasets = this.chart.parkingTurnoverData.datasets;
      }
      this.setArea();
      this.setChartGranularity();
    },
    setArea() {
      this.chart.area = [];
      if (this.parkingLotData && this.parkingLotData.parking_zones) {
        // Spots zones
        let normalSpotTrackingZones = this.parkingLotData.parking_zones.filter(
          (zone) => zone.zone_type === "normal_spots_zone"
        );
        if (normalSpotTrackingZones.length > 0) {
          this.chart.area = [
            { header: "Spot Tracking Zones" },
            { label: "Full Lot", value: -1 },
          ];
          if (this.selectedChartType === "occupancy") {
            this.chart.area.push({ label: "Zone Wise", value: 0 });
          }

          for (const zone of normalSpotTrackingZones) {
            this.chart.area.push({
              label: zone.name ? zone.name : `Zone-${zone.id}`,
              value: zone.id,
            });
          }
        }

        // LPR Untracked Zones
        let lprCounterZones = this.parkingLotData.parking_zones.filter(
          (zone) => zone.zone_type === "lpr_counter_zone"
        );
        if (
          this.IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED &&
          lprCounterZones.length > 0
        ) {
          this.chart.area.push({ header: "LPR Car Counting Zones" });
          for (const zone of lprCounterZones) {
            this.chart.area.push({
              label: zone.name ? zone.name : `Zone-${zone.id}`,
              value: zone.id,
            });
          }
        }

        // Other Types of car counting zones that don't directly support
        // dwell time or turnover charts but they have nested child zones
        // and we can display that data.
        let otherCounterZones = this.parkingLotData.parking_zones.filter(
          (zone) => {
            if (
              zone.zone_type != "lpr_counter_zone" &&
              zone.zone_type != "normal_spots_zone" &&
              zone.zone_type != "time_limited_zone"
            ) {
              // Check if this zone has nested child zones that are either
              // normal_spots_zone or lpr_counter_zone
              let childZones = this.parkingLotData.parking_zones.filter(
                (childZone) =>
                  childZone.nested_parent_zone_id === zone.id &&
                  (childZone.zone_type === "normal_spots_zone" ||
                    childZone.zone_type === "lpr_counter_zone")
              );
              if (childZones.length > 0) {
                return true;
              }
            }
            return false;
          }
        );
        if (otherCounterZones.length > 0) {
          this.chart.area.push({
            header: "Other Car Counting Zones (Indirect Calculation)",
          });
          for (const zone of otherCounterZones) {
            this.chart.area.push({
              label: zone.name ? zone.name : `Zone-${zone.id}`,
              value: zone.id,
            });
          }
        }

        // Time limited (Pick up / Drop off zones)
        let timeLimitedZones = this.parkingLotData.parking_zones.filter(
          (zone) => zone.zone_type === "time_limited_zone"
        );
        if (timeLimitedZones.length > 0) {
          this.chart.area.push({ header: "Drop Off/Pick Up Zones" });
          for (const zone of timeLimitedZones) {
            this.chart.area.push({
              label: zone.name ? zone.name : `Zone-${zone.id}`,
              value: zone.id,
            });
          }
        }
      }
    },
    showActualOccupiedSpotCount() {
      if (this.chart.showActualValues) {
        this.chart.options.scales.y.title.text = "Spots Occupied";
      } else {
        this.chart.options.scales.y.title.text = "% Occupied";
      }
      this.fetchData(false);
    },
    ignoreUnmappedSpotsOnChart() {
      this.fetchData(this.selectedChartType === "turnover");
    },
    async fetchOccupanciesChartData(callapi = true) {
      try {
        if (callapi) {
          this.occupanciesData = await api.getDetailedOccupancies(
            this.lotId,
            this.chart.selectedArea,
            `${this.chart.startDate} ${this.chart.startTime}`,
            `${this.chart.endDate} ${this.chart.endTime}`,
            this.chart.selectedGranularity,
            this.chart.selectedSpotPermitType,
            this.chart.selectedArea > 0 ? "parking_zone" : "parking_lot"
          );
        }

        let datasets = [] as Array<BarChartDataset>;
        let final_x_axis_labels_colors = [] as Array<string>;
        if (this.occupanciesData) {
          const granularity = this.chart.granularity.find(
            (g) => g.value === this.chart.selectedGranularity
          )?.label;
          const diffDays = this.getDateDiffInDays(
            this.chart.startDate,
            this.chart.endDate
          );
          const selectedArea = this.chart.area.find(
            (a) => a.value === this.chart.selectedArea
          );
          if (selectedArea) {
            this.chart.options.plugins.title.text = `${
              selectedArea.label
            } Occupancies - ${
              this.singleDaySelected
                ? dayjs(this.chart.endDate).format("dddd, MMMM D, YYYY")
                : `${dayjs(this.chart.startDate).format(
                    "dddd, MMMM D, YYYY"
                  )} - ${dayjs(this.chart.endDate).format(
                    "dddd, MMMM D, YYYY"
                  )}`
            }`;
          }
          let timestamps = [] as Array<string>;
          const timeRangeStart = dayjs(
            `${this.chart.startDate}T${this.chart.startTime}`
          );
          const timeRangeEnd = dayjs(
            `${this.chart.endDate}T${
              dayjs().format("YYYY-MM-DD") == this.chart.endDate
                ? dayjs().format("HH:mm")
                : this.chart.endTime
            }`
          );
          const hours = timeRangeEnd.diff(timeRangeStart, "hours");
          const days = timeRangeEnd.diff(timeRangeStart, "days");

          if (diffDays <= 1) {
            const granularity = this.chart.selectedGranularity / 60;
            for (let i = 0; i <= hours; i++) {
              for (let j = 0; j < 60; j += granularity) {
                const startTime = timeRangeStart
                  .add(i, "hour")
                  .add(j, "minute");
                const endTime = startTime.add(granularity, "minute");
                if (diffDays < 1) {
                  const startString = startTime.format("h:mm A");
                  const endString = endTime.format("h:mm A");
                  timestamps.push(`${endString}`);
                } else {
                  const startString = startTime.format(
                    "dddd, MMMM D, YYYY [at] h:mm A"
                  );
                  const endString = endTime.format("h:mm A");
                  timestamps.push(`${startString} - ${endString}`);
                }
                if (endTime.isAfter(dayjs())) {
                  break;
                }
              }
            }
          } else {
            if (this.chart.selectedGranularity == 60 * 60) {
              for (let i = 0; i <= hours; i++) {
                const startTime = timeRangeStart.add(i, "hour");
                const startString = startTime.format(
                  "dddd, MMMM D, YYYY [at] h:00 A - h:59 A"
                );
                timestamps.push(`${startString}`);
              }
            }
          }
          for (let row of this.occupanciesData) {
            const d = new Date(row.local_time);
            if (this.chart.selectedGranularity > 60 * 60) {
              timestamps.push(
                `${d.toLocaleString("en-us", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                  weekday: "long",
                })}`
              );
              this.chart.options.scales.x.title.text = "Date";
              if (this.chart.selectedGranularity === 60 * 60 * 24)
                this.chart.options.scales.x.title.text = "Each Day";
              else if (this.chart.selectedGranularity === 60 * 60 * 24 * 7)
                this.chart.options.scales.x.title.text = "Each Week";
            } else if (diffDays < 1) {
              this.chart.options.scales.x.title.text = `Date - ${new Date(
                `${this.chart.endDate} 23:59`
              ).toLocaleString("en-us", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                weekday: "long",
              })}, ${this.timeConvert(
                this.chart.startTime
              )} - ${this.timeConvert(
                dayjs().format("YYYY-MM-DD") == this.chart.endDate
                  ? dayjs().format("HH:mm")
                  : this.chart.endTime
              )}, (${granularity})`;
            } else {
              this.chart.options.scales.x.title.text = "Date and Time";
            }
          }
          let data = [] as Array<number>;
          let total_data = [] as Array<number>;
          if (this.chart.selectedArea == -1) {
            const timestamps = [
              ...new Set(this.occupanciesData.map((o) => o.ts)),
            ];
            for (let ts of timestamps) {
              let occupancies = this.occupanciesData.filter((o) => o.ts === ts);
              if (occupancies.find((o) => o.parking_zone_id == null)) {
                occupancies = occupancies.filter(
                  (o) => o.parking_zone_id == null
                );
              }

              let occ_count = 0;
              let tot_count = 0;
              let tot_camera_mapped_spots = 0;
              for (let occupancy of occupancies) {
                occ_count += occupancy.occ_count;
                tot_count += occupancy.tot_count;
                tot_camera_mapped_spots += occupancy.tot_camera_mapped_spots;
              }
              if (this.chart.showActualValues) {
                data.push(occ_count);
                if (this.chart.ignoreUnmappedSpots) {
                  total_data.push(
                    tot_camera_mapped_spots != 0 &&
                      tot_count >= tot_camera_mapped_spots
                      ? tot_camera_mapped_spots
                      : tot_count
                  );
                  final_x_axis_labels_colors.push(
                    tot_camera_mapped_spots != 0 &&
                      tot_count >= tot_camera_mapped_spots
                      ? "rgb(0,0,0)"
                      : "#A9A9A9"
                  );
                } else {
                  total_data.push(tot_count);
                  final_x_axis_labels_colors.push("rgb(0,0,0)");
                }
              } else {
                data.push(
                  occ_count == 0
                    ? 0
                    : (occ_count /
                        (this.chart.ignoreUnmappedSpots &&
                        tot_camera_mapped_spots != 0
                          ? tot_camera_mapped_spots
                          : tot_count)) *
                        100
                );
                if (this.chart.ignoreUnmappedSpots) {
                  total_data.push(
                    occ_count == 0
                      ? 0
                      : tot_camera_mapped_spots != 0 &&
                        tot_count >= tot_camera_mapped_spots
                      ? tot_camera_mapped_spots
                      : tot_count
                  );
                  final_x_axis_labels_colors.push(
                    tot_camera_mapped_spots != 0 &&
                      tot_count >= tot_camera_mapped_spots
                      ? "rgb(0,0,0)"
                      : "#A9A9A9"
                  );
                } else {
                  total_data.push(tot_count);
                  final_x_axis_labels_colors.push("rgb(0,0,0)");
                }
              }
            }
            datasets.push({
              label: this.parkingLotName
                ? this.parkingLotName
                : this.parkingLotData && this.parkingLotData.name
                ? this.parkingLotData.name
                : `Lot-${this.lotId}`,
              data,
              total_data,
              borderColor: "#FFFFFF",
              backgroundColor: "#6dbe45",
              barPercentage: 1.0,
              showActualValues: this.chart.showActualValues,
              ignoreUnmappedSpots: this.chart.ignoreUnmappedSpots,
              showLabels: false,
              selectedChartType: this.selectedChartType,
            });
            data = [];
          } else if (this.parkingLotData && this.parkingLotData.parking_zones) {
            let index = 0;
            for (const zone of this.parkingLotData.parking_zones) {
              if (
                this.chart.selectedArea != 0 &&
                this.chart.selectedArea != zone.id
              )
                continue;
              let zone_rows = this.occupanciesData.filter(
                (row) => row.parking_zone_id == zone.id
              );
              for (let row of zone_rows) {
                if (this.chart.showActualValues) {
                  data.push(row.occ_count);
                  if (this.chart.ignoreUnmappedSpots) {
                    total_data.push(
                      row.tot_camera_mapped_spots != 0 &&
                        row.tot_count >= row.tot_camera_mapped_spots
                        ? row.tot_camera_mapped_spots
                        : row.tot_count
                    );
                    final_x_axis_labels_colors.push(
                      row.tot_camera_mapped_spots != 0 &&
                        row.tot_count >= row.tot_camera_mapped_spots
                        ? "rgb(0,0,0)"
                        : "#A9A9A9"
                    );
                  } else {
                    total_data.push(row.tot_count);
                    final_x_axis_labels_colors.push("rgb(0,0,0)");
                  }
                } else {
                  data.push((row.occ_count / row.tot_count) * 100);
                  if (this.chart.ignoreUnmappedSpots) {
                    total_data.push(
                      row.tot_camera_mapped_spots != 0 &&
                        row.tot_count >= row.tot_camera_mapped_spots
                        ? row.tot_camera_mapped_spots
                        : row.tot_count
                    );
                    final_x_axis_labels_colors.push(
                      row.tot_camera_mapped_spots != 0 &&
                        row.tot_count >= row.tot_camera_mapped_spots
                        ? "rgb(0,0,0)"
                        : "#A9A9A9"
                    );
                  } else {
                    total_data.push(row.tot_count);
                    final_x_axis_labels_colors.push("rgb(0,0,0)");
                  }
                }
              }
              datasets.push({
                label: zone.name ? zone.name : `Zone-${zone.id}`,
                data,
                total_data,
                borderColor: "#FFFFFF",
                backgroundColor: this.generateRandomColor(index),
                barPercentage: 1.0,
                showActualValues: this.chart.showActualValues,
                ignoreUnmappedSpots: this.chart.ignoreUnmappedSpots,
                showLabels: this.chart.selectedArea === 0,
                selectedChartType: this.selectedChartType,
              });
              data = [];
              index += 1;
            }
            index = 0;
          }
          timestamps = timestamps.filter(
            (item, index) => timestamps.indexOf(item) === index
          );

          this.chart.options.scales.x.ticks.color = final_x_axis_labels_colors;

          this.chart.occupanciesData.labels = timestamps;
          this.chart.occupanciesData.datasets = datasets;

          this.chart.data.labels = timestamps;
          this.chart.data.datasets = datasets;

          if (this.isLoggedIn) {
            this.adjustChartSize("1");
          }
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, unable to load latest data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        if (this.occupanciesChart) this.occupanciesChart.update();
        this.chart.isLoading = false;
      }
    },
    async fetchDwellTimeChartData(callapi = true) {
      try {
        if (callapi) {
          this.dwellTimeData = await api.getOccupanciesDwellTime(
            this.lotId,
            this.chart.selectedArea,
            `${this.chart.startDate} ${this.chart.startTime}`,
            `${this.chart.endDate} ${this.chart.endTime}`,
            this.chart.selectedGranularity,
            this.chart.selectedSpotPermitType,
            this.chart.ignoreParkingSessionsUnder
          );
          console.log("Dwell Time: ", this.dwellTimeData);
        }
        let datasets = [] as Array<LineChartDataset>;
        if (this.dwellTimeData && Array.isArray(this.dwellTimeData)) {
          const granularity = this.chart.granularity.find(
            (g) => g.value === this.chart.selectedGranularity
          )?.label;
          const diffDays = this.getDateDiffInDays(
            this.chart.startDate,
            this.chart.endDate
          );
          const selectedArea = this.chart.area.find(
            (a) => a.value === this.chart.selectedArea
          );
          if (selectedArea) {
            this.chart.options.plugins.title.text = `${
              selectedArea.label
            } Dwell Time - ${
              this.singleDaySelected
                ? dayjs(this.chart.endDate).format("dddd, MMMM D, YYYY")
                : `${dayjs(this.chart.startDate).format(
                    "dddd, MMMM D, YYYY"
                  )} - ${dayjs(this.chart.endDate).format(
                    "dddd, MMMM D, YYYY"
                  )}`
            }`;
          }
          let timestamps = [] as Array<string>;
          const timeRangeStart = dayjs(
            `${this.chart.startDate}T${this.chart.startTime}`
          );
          const timeRangeEnd = dayjs(
            `${this.chart.endDate}T${
              dayjs().format("YYYY-MM-DD") == this.chart.endDate
                ? dayjs().format("HH:mm")
                : this.chart.endTime
            }`
          );
          const hours = timeRangeEnd.diff(timeRangeStart, "hours");
          const days = timeRangeEnd.diff(timeRangeStart, "days");
          if (diffDays <= 1) {
            for (let i = 0; i <= hours; i++) {
              const startTime = timeRangeStart.add(i + 1, "hour");
              const startString = startTime.format("h:00 A");
              timestamps.push(`${startString}`);
            }
          } else {
            if (this.chart.selectedGranularity == 60 * 60) {
              for (let i = 0; i <= hours; i++) {
                const startTime = timeRangeStart.add(i, "hour");
                const startString = startTime.format(
                  "dddd, MMMM D, YYYY [at] h:00 A - h:59 A"
                );
                timestamps.push(`${startString}`);
              }
            } else if (this.chart.selectedGranularity == 60 * 60 * 24 * 7) {
              for (let i = 0; i < this.dwellTimeData.length; i++) {
                const currentRow = this.dwellTimeData[i];
                const nextRow = this.dwellTimeData[i + 1];

                const currentD = new Date(currentRow.local_time);
                const nextD =
                  i == this.dwellTimeData.length - 1
                    ? new Date(this.chart.endDate)
                    : new Date(nextRow.local_time);

                const startTime = dayjs(currentD);
                const startString = startTime.format("dddd, MMMM D, YYYY");

                const endTime = dayjs(nextD).subtract(1, "day");
                const endString = endTime.format("dddd, MMMM D, YYYY");

                timestamps.push(`${startString} - ${endString}`);
              }
            } else {
              for (let i = 0; i <= days; i++) {
                const startTime = timeRangeStart.add(i, "day");
                const startString = startTime.format("dddd, MMMM D, YYYY");
                timestamps.push(`${startString}`);
              }
            }
          }
          for (let row of this.dwellTimeData) {
            const d = new Date(row.local_time);
            if (this.chart.selectedGranularity > 60 * 60) {
              this.chart.options.scales.x.title.text = "Date";
              if (this.chart.selectedGranularity === 60 * 60 * 24)
                this.chart.options.scales.x.title.text = "Each Day";
              else if (this.chart.selectedGranularity === 60 * 60 * 24 * 7)
                this.chart.options.scales.x.title.text = "Each Week";
            } else if (diffDays < 1) {
              this.chart.options.scales.x.title.text = `Date - ${new Date(
                `${this.chart.endDate} 23:59`
              ).toLocaleString("en-us", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                weekday: "long",
              })}, ${this.timeConvert(
                this.chart.startTime
              )} - ${this.timeConvert(
                dayjs().format("YYYY-MM-DD") == this.chart.endDate
                  ? dayjs().format("HH:mm")
                  : this.chart.endTime
              )}, (${granularity})`;
            } else {
              if (this.chart.selectedGranularity != 60 * 60) {
                timestamps.push(
                  `${d.toLocaleString("en-US", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    weekday: "long",
                    hour12: true,
                  })}`
                );
              }
              this.chart.options.scales.x.title.text = "Date and Time";
            }
          }
          let data_time_0 = [] as Array<number>;
          let data_time_1to15 = [] as Array<number>;
          let data_time_15to60 = [] as Array<number>;
          let data_time_1hour = [] as Array<number>;
          let data_time_2hour = [] as Array<number>;
          let data_time_3hour = [] as Array<number>;
          let data_time_4plushour = [] as Array<number>;

          const data_timestamps = [
            ...new Set(this.dwellTimeData.map((o) => o.ts)),
          ];
          if (this.chart.selectedGranularity == 60 * 60) {
            if (this.chart.showActualValues) {
              this.chart.options.scales.y.title.text =
                "Number of Parking Sessions";
            } else {
              this.chart.options.scales.y.title.text = "% of Parking Sessions";
            }
            console.log("timestamps: ", timestamps);
            for (let timestamp of timestamps) {
              const dwell_time = this.dwellTimeData.find((o) =>
                diffDays <= 1
                  ? dayjs(o.local_time).add(1, "hour").format("h:00 A") ===
                    timestamp
                  : dayjs(o.local_time).format(
                      "dddd, MMMM D, YYYY [at] h:00 A - h:59 A"
                    ) === timestamp
              );
              if (dwell_time) {
                if (!this.chart.showActualValues) {
                  this.chart.options.scales.y.title.text =
                    "% of Parking Sessions";
                  const total_parking_sessions =
                    /* dwell_time.time_0 +*/ dwell_time.time_1to15 +
                    dwell_time.time_15to60 +
                    dwell_time.time_1hour +
                    dwell_time.time_2hour +
                    dwell_time.time_3hour +
                    dwell_time.time_4plushour;
                  data_time_0.push(
                    Math.round(
                      ((dwell_time.time_0 / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_1to15.push(
                    Math.round(
                      ((dwell_time.time_1to15 / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_15to60.push(
                    Math.round(
                      ((dwell_time.time_15to60 / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_1hour.push(
                    Math.round(
                      ((dwell_time.time_1hour / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_2hour.push(
                    Math.round(
                      ((dwell_time.time_2hour / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_3hour.push(
                    Math.round(
                      ((dwell_time.time_3hour / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_4plushour.push(
                    Math.round(
                      ((dwell_time.time_4plushour / total_parking_sessions) *
                        100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                } else {
                  this.chart.options.scales.y.title.text =
                    "Number of Parking Sessions";
                  data_time_0.push(dwell_time.time_0);
                  data_time_1to15.push(dwell_time.time_1to15);
                  data_time_15to60.push(dwell_time.time_15to60);
                  data_time_1hour.push(dwell_time.time_1hour);
                  data_time_2hour.push(dwell_time.time_2hour);
                  data_time_3hour.push(dwell_time.time_3hour);
                  data_time_4plushour.push(dwell_time.time_4plushour);
                }
              } else {
                data_time_0.push(0);
                data_time_1to15.push(0);
                data_time_15to60.push(0);
                data_time_1hour.push(0);
                data_time_2hour.push(0);
                data_time_3hour.push(0);
                data_time_4plushour.push(0);
              }
            }
          } else {
            for (let timestamp of timestamps) {
              const dwell_time = this.dwellTimeData.find(
                (o) =>
                  dayjs(o.local_time).format("dddd, MMMM D, YYYY") ===
                    timestamp ||
                  timestamp.includes(
                    dayjs(o.local_time).format("dddd, MMMM D, YYYY")
                  )
              );
              if (dwell_time) {
                if (!this.chart.showActualValues) {
                  this.chart.options.scales.y.title.text =
                    "% of Parking Sessions";
                  const total_parking_sessions =
                    /* dwell_time.time_0 +*/ dwell_time.time_1to15 +
                    dwell_time.time_15to60 +
                    dwell_time.time_1hour +
                    dwell_time.time_2hour +
                    dwell_time.time_3hour +
                    dwell_time.time_4plushour;
                  data_time_0.push(
                    Math.round(
                      ((dwell_time.time_0 / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_1to15.push(
                    Math.round(
                      ((dwell_time.time_1to15 / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_15to60.push(
                    Math.round(
                      ((dwell_time.time_15to60 / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_1hour.push(
                    Math.round(
                      ((dwell_time.time_1hour / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_2hour.push(
                    Math.round(
                      ((dwell_time.time_2hour / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_3hour.push(
                    Math.round(
                      ((dwell_time.time_3hour / total_parking_sessions) * 100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                  data_time_4plushour.push(
                    Math.round(
                      ((dwell_time.time_4plushour / total_parking_sessions) *
                        100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  );
                } else {
                  this.chart.options.scales.y.title.text =
                    "Number of Parking Sessions";
                  data_time_0.push(dwell_time.time_0);
                  data_time_1to15.push(dwell_time.time_1to15);
                  data_time_15to60.push(dwell_time.time_15to60);
                  data_time_1hour.push(dwell_time.time_1hour);
                  data_time_2hour.push(dwell_time.time_2hour);
                  data_time_3hour.push(dwell_time.time_3hour);
                  data_time_4plushour.push(dwell_time.time_4plushour);
                }
              } else {
                data_time_0.push(0);
                data_time_1to15.push(0);
                data_time_15to60.push(0);
                data_time_1hour.push(0);
                data_time_2hour.push(0);
                data_time_3hour.push(0);
                data_time_4plushour.push(0);
              }
            }
          }
          datasets.push(
            ...[
              // {
              //   label: "<1 min",
              //   data: data_time_0,
              //   borderColor: "#b5dea1",
              //   backgroundColor: "#b5dea1",
              //   fill: !this.chart.showActualValues,
              //   showLabels: true,
              //   selectedChartType: this.selectedChartType,
              //   showActualValues: this.chart.showActualValues,
              // },
              {
                label: "<15 min",
                data: data_time_1to15,
                borderColor: this.chart.showActualValues
                  ? "#b5dea1"
                  : "#FFFFFF",
                backgroundColor: "#b5dea1",
                fill: !this.chart.showActualValues,
                showLabels: true,
                selectedChartType: this.selectedChartType,
                showActualValues: this.chart.showActualValues,
                ignoreUnmappedSpots: this.chart.ignoreUnmappedSpots,
              },
              {
                label: "15-60 min",
                data: data_time_15to60,
                borderColor: this.chart.showActualValues
                  ? "#97d17b"
                  : "#FFFFFF",
                backgroundColor: "#97d17b",
                fill: !this.chart.showActualValues,
                showLabels: true,
                selectedChartType: this.selectedChartType,
                showActualValues: this.chart.showActualValues,
                ignoreUnmappedSpots: this.chart.ignoreUnmappedSpots,
              },
              {
                label: ">1 hour",
                data: data_time_1hour,
                borderColor: this.chart.showActualValues
                  ? "#7ac455"
                  : "#FFFFFF",
                backgroundColor: "#7ac455",
                fill: !this.chart.showActualValues,
                showLabels: true,
                selectedChartType: this.selectedChartType,
                showActualValues: this.chart.showActualValues,
                ignoreUnmappedSpots: this.chart.ignoreUnmappedSpots,
              },
              {
                label: ">2 hour",
                data: data_time_2hour,
                borderColor: this.chart.showActualValues
                  ? "#60aa3b"
                  : "#FFFFFF",
                backgroundColor: "#60aa3b",
                fill: !this.chart.showActualValues,
                showLabels: true,
                selectedChartType: this.selectedChartType,
                showActualValues: this.chart.showActualValues,
                ignoreUnmappedSpots: this.chart.ignoreUnmappedSpots,
              },
              {
                label: ">3 hour",
                data: data_time_3hour,
                borderColor: this.chart.showActualValues
                  ? "#4b842e"
                  : "#FFFFFF",
                backgroundColor: "#4b842e",
                fill: !this.chart.showActualValues,
                showLabels: true,
                selectedChartType: this.selectedChartType,
                showActualValues: this.chart.showActualValues,
                ignoreUnmappedSpots: this.chart.ignoreUnmappedSpots,
              },
              {
                label: ">4 hour",
                data: data_time_4plushour,
                borderColor: this.chart.showActualValues
                  ? "#355e21"
                  : "#FFFFFF",
                backgroundColor: "#355e21",
                fill: !this.chart.showActualValues,
                showLabels: true,
                selectedChartType: this.selectedChartType,
                showActualValues: this.chart.showActualValues,
                ignoreUnmappedSpots: this.chart.ignoreUnmappedSpots,
              },
            ]
          );
          data_time_0 = [];
          data_time_1to15 = [];
          data_time_15to60 = [];
          data_time_1hour = [];
          data_time_2hour = [];
          data_time_3hour = [];
          data_time_4plushour = [];

          timestamps = timestamps.filter(
            (item, index) => timestamps.indexOf(item) === index
          );
          this.chart.dwellTimeData.labels = timestamps;
          this.chart.dwellTimeData.datasets = datasets;

          this.chart.data.labels = timestamps;
          this.chart.data.datasets = datasets;

          if (this.isLoggedIn) {
            this.adjustChartSize("2");
          }
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, unable to load latest occupancies dwell time data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        if (this.dwellTimeChart) this.dwellTimeChart.update();
        this.chart.isLoading = false;
      }
    },
    async fetchParkingTurnoverChartData(callapi = true) {
      try {
        if (callapi) {
          this.parkingTurnoverData = await api.getOccupanciesParkingTurnover(
            this.lotId,
            this.chart.selectedArea,
            `${this.chart.startDate} ${this.chart.startTime}`,
            `${this.chart.endDate} ${this.chart.endTime}`,
            this.chart.selectedGranularity,
            this.chart.selectedSpotPermitType,
            this.chart.ignoreUnmappedSpots
          );
          console.log("Parking Turnover: ", this.parkingTurnoverData);
        }
        if (
          this.parkingTurnoverData &&
          Array.isArray(this.parkingTurnoverData)
        ) {
          let datasets = [] as Array<LineChartDataset>;
          const granularity = this.chart.granularity.find(
            (g) => g.value === this.chart.selectedGranularity
          )?.label;
          const diffDays = this.getDateDiffInDays(
            this.chart.startDate,
            this.chart.endDate
          );
          const selectedArea = this.chart.area.find(
            (a) => a.value === this.chart.selectedArea
          );
          if (selectedArea) {
            const number_of_spots =
              this.parkingTurnoverData.length > 0
                ? this.parkingTurnoverData[0].number_of_spots
                : 0;
            this.chart.options.plugins.title.text = `${
              selectedArea.label
            } Parking Turnover${
              number_of_spots ? `, Total Spots: ${number_of_spots}` : ""
            } - ${
              this.singleDaySelected
                ? dayjs(this.chart.endDate).format("dddd, MMMM D, YYYY")
                : `${dayjs(this.chart.startDate).format(
                    "dddd, MMMM D, YYYY"
                  )} - ${dayjs(this.chart.endDate).format(
                    "dddd, MMMM D, YYYY"
                  )}`
            }`;
          }
          let timestamps = [] as Array<string>;
          const timeRangeStart = dayjs(
            `${this.chart.startDate}T${this.chart.startTime}`
          );
          const timeRangeEnd = dayjs(
            `${this.chart.endDate}T${
              dayjs().format("YYYY-MM-DD") == this.chart.endDate
                ? dayjs().format("HH:mm")
                : this.chart.endTime
            }`
          );
          const hours = timeRangeEnd.diff(timeRangeStart, "hours");
          const days = timeRangeEnd.diff(timeRangeStart, "days");
          if (diffDays <= 1) {
            for (let i = 0; i <= hours; i++) {
              const startTime = timeRangeStart.add(i + 1, "hour");
              const startString = startTime.format("h:00 A");
              timestamps.push(`${startString}`);
            }
          } else {
            if (this.chart.selectedGranularity == 60 * 60) {
              for (let i = 0; i <= hours; i++) {
                const startTime = timeRangeStart.add(i, "hour");
                const startString = startTime.format(
                  "dddd, MMMM D, YYYY [at] h:00 A - h:59 A"
                );
                timestamps.push(`${startString}`);
              }
            } else if (this.chart.selectedGranularity == 60 * 60 * 24 * 7) {
              for (let i = 0; i < this.parkingTurnoverData.length; i++) {
                const currentRow = this.parkingTurnoverData[i];
                const nextRow = this.parkingTurnoverData[i + 1];

                const currentD = new Date(currentRow.local_time);
                const nextD =
                  i == this.parkingTurnoverData.length - 1
                    ? new Date(this.chart.endDate)
                    : new Date(nextRow.local_time);

                const startTime = dayjs(currentD);
                const startString = startTime.format("dddd, MMMM D, YYYY");

                const endTime = dayjs(nextD).subtract(1, "day");
                const endString = endTime.format("dddd, MMMM D, YYYY");

                timestamps.push(`${startString} - ${endString}`);
              }
            } else {
              for (let i = 0; i <= days; i++) {
                const startTime = timeRangeStart.add(i, "day");
                const startString = startTime.format("dddd, MMMM D, YYYY");
                timestamps.push(`${startString}`);
              }
            }
          }
          for (let row of this.parkingTurnoverData) {
            const d = new Date(row.local_time);
            if (this.chart.selectedGranularity > 60 * 60) {
              this.chart.options.scales.x.title.text = "Date";
              if (this.chart.selectedGranularity === 60 * 60 * 24)
                this.chart.options.scales.x.title.text = "Each Day";
              else if (this.chart.selectedGranularity === 60 * 60 * 24 * 7)
                this.chart.options.scales.x.title.text = "Each Week";
            } else if (diffDays < 1) {
              this.chart.options.scales.x.title.text = `Date - ${new Date(
                `${this.chart.endDate} 23:59`
              ).toLocaleString("en-us", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                weekday: "long",
              })}, ${this.timeConvert(
                this.chart.startTime
              )} - ${this.timeConvert(
                dayjs().format("YYYY-MM-DD") == this.chart.endDate
                  ? dayjs().format("HH:mm")
                  : this.chart.endTime
              )}, (${granularity})`;
            } else {
              if (this.chart.selectedGranularity != 60 * 60) {
                timestamps.push(
                  `${d.toLocaleString("en-US", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    weekday: "long",
                    hour12: true,
                  })}`
                );
              }
              this.chart.options.scales.x.title.text = "Date and Time";
            }
          }
          let data = [] as Array<number>;

          const data_timestamps = [
            ...new Set(this.parkingTurnoverData.map((o) => o.ts)),
          ];
          if (this.chart.selectedGranularity == 60 * 60) {
            if (this.chart.showActualValues) {
              this.chart.options.scales.y.title.text =
                "Number of Parking Sessions";
            } else {
              this.chart.options.scales.y.title.text = "Parking Turnover";
            }
            for (let timestamp of timestamps) {
              const to = this.parkingTurnoverData.find((o) =>
                diffDays <= 1
                  ? dayjs(o.local_time).add(1, "hour").format("h:00 A") ===
                    timestamp
                  : dayjs(o.local_time).format(
                      "dddd, MMMM D, YYYY [at] h:00 A - h:59 A"
                    ) === timestamp
              );
              if (to) {
                if (this.chart.showActualValues) {
                  data.push(to.parking_sessions);
                } else {
                  data.push(this.roundValue(to.turnover));
                }
              } else {
                data.push(0);
              }
            }
          } else {
            for (let timestamp of timestamps) {
              const to = this.parkingTurnoverData.find(
                (o) =>
                  dayjs(o.local_time).format("dddd, MMMM D, YYYY") ===
                    timestamp ||
                  timestamp.includes(
                    dayjs(o.local_time).format("dddd, MMMM D, YYYY")
                  )
              );
              if (to) {
                if (this.chart.showActualValues) {
                  this.chart.options.scales.y.title.text =
                    "Number of Parking Sessions";
                  data.push(to.parking_sessions);
                } else {
                  this.chart.options.scales.y.title.text = "Parking Turnover";
                  data.push(this.roundValue(to.turnover));
                }
              } else {
                data.push(0);
              }
            }
          }
          datasets.push({
            label: "Turnover",
            data,
            borderColor: "#6dbe45",
            backgroundColor: "#A7D88F",
            fill: !this.chart.showActualValues,
            showLabels: true,
            selectedChartType: this.selectedChartType,
            showActualValues: this.chart.showActualValues,
            ignoreUnmappedSpots: this.chart.ignoreUnmappedSpots,
          });
          data = [];

          timestamps = timestamps.filter(
            (item, index) => timestamps.indexOf(item) === index
          );
          this.chart.parkingTurnoverData.labels = timestamps;
          this.chart.parkingTurnoverData.datasets = datasets;

          this.chart.data.labels = timestamps;
          this.chart.data.datasets = datasets;

          if (this.isLoggedIn) {
            this.adjustChartSize("3");
          }
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, unable to load latest occupancies parking turnover data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        if (this.parkingTurnoverChart) this.parkingTurnoverChart.update();
        this.chart.isLoading = false;
      }
    },
    async fetchPermitTypes() {
      // Only get all permits that are valid (i.e. currently in use on the PL)
      let permitTypes = await api.getAllParkingPermits(this.lotId, true);
      if (permitTypes != null) {
        this.chart.permitTypes = permitTypes;
      }
    },
    adjustChartSize(chart_class = "1") {
      // set width of the chart based on number of labels
      const chartContainer: HTMLElement | null = document.querySelector(
        `.occChartAreaWrapper${chart_class}`
      );
      if (chartContainer) {
        // 60px is enough to show one data point
        const singleDataPointWidth =
          this.chart.selectedArea !== 0
            ? 60
            : this.parkingLotData.parking_zones.length * 25;
        const numberOfViewableDataPoints =
          (window.innerWidth * singleDataPointWidth) /
          100 /
          singleDataPointWidth;
        if (this.chart.data.labels.length > numberOfViewableDataPoints) {
          chartContainer.style.width = `${
            this.chart.data.labels.length * singleDataPointWidth
          }px`;
        } else {
          chartContainer.style.width = "95vw";
        }
      }
    },
    setChartDates() {
      const today = new Date();
      switch (this.chart.selectedDuration) {
        case 0:
          if (this.selectedChartType === "occupancy") {
            this.chart.startDate = this.todaysDate;
          } else {
            this.chart.startDate = this.yesterdaysDate;
          }
          this.chart.startTime = "00:00";
          this.chart.endTime = "23:59";
          break;
        case 1:
          this.chart.startDate = this.getLocalDate(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
          );
          break;
        case 2:
          this.chart.startDate = this.getLocalDate(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 30
            )
          );
          break;
        case 3:
          this.chart.startDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth() - 1, 1)
          );
          this.chart.endDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth(), 0)
          );
          break;
        default:
          this.chart.startDate = this.getLocalDate(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
          );
          break;
      }
      if (this.chart.selectedDuration !== 3) {
        if (this.selectedChartType === "occupancy") {
          this.chart.endDate = this.todaysDate;
        } else {
          this.chart.endDate = this.yesterdaysDate;
        }
      }

      this.chart.granularity = [
        { label: "5 Minutes", value: 60 * 5 },
        { label: "15 Minutes", value: 60 * 15 },
        { label: "30 Minutes", value: 60 * 30 },
        { label: "Hourly", value: 60 * 60 },
        { label: "Daily", value: 60 * 60 * 24 },
        { label: "Weekly", value: 60 * 60 * 24 * 7 },
      ].filter((g, i) => {
        switch (this.chart.selectedDuration) {
          case 0:
            return [1, 2, 3].includes(i);
          case 1:
            return [3, 4].includes(i);
          case 2:
            return [4, 5].includes(i);
          case 3:
            return [4, 5].includes(i);
          default:
            return [3, 4, 5].includes(i);
        }
      });
      this.chart.selectedGranularity = this.chart.granularity[0].value;
      if (this.chart.selectedDuration == 0) {
        this.chart.selectedGranularity = 60 * 30;
      } else if (this.chart.selectedDuration == 1) {
        this.chart.selectedGranularity = 60 * 60 * 24;
      }
      this.setChartGranularity();
    },
    setChartGranularity(set_default_granularity = false) {
      // Set granularity options and selection for Custom start and end date
      if (this.chart.selectedDuration === 4) {
        const diffDays = this.getDateDiffInDays(
          this.chart.startDate,
          this.chart.endDate
        );
        if (diffDays > 1) {
          if (diffDays <= 7) {
            this.chart.granularity = [{ label: "Daily", value: 60 * 60 * 24 }];
            if (!set_default_granularity) {
              this.chart.selectedGranularity = 60 * 60 * 24;
            }
          } else {
            this.chart.granularity = [
              { label: "Daily", value: 60 * 60 * 24 },
              { label: "Weekly", value: 60 * 60 * 24 * 7 },
            ];
            if (!set_default_granularity) {
              if (diffDays > 30) {
                this.chart.selectedGranularity = 60 * 60 * 24 * 7;
              } else {
                this.chart.selectedGranularity = 60 * 60 * 24;
              }
            }
          }
        } else {
          if (this.selectedChartType === "occupancy") {
            this.chart.granularity = [
              { label: "5 Minutes", value: 60 * 5 },
              { label: "15 Minutes", value: 60 * 15 },
              { label: "30 Minutes", value: 60 * 30 },
              { label: "Hourly", value: 60 * 60 },
            ];
          } else {
            this.chart.granularity = [{ label: "Hourly", value: 60 * 60 }];
          }
          if (!set_default_granularity) {
            this.chart.selectedGranularity = 60 * 60;
          }
        }
      } else {
        if (this.chart.selectedDuration == 0) {
          if (this.selectedChartType === "occupancy") {
            this.chart.granularity = [
              { label: "5 Minutes", value: 60 * 5 },
              { label: "15 Minutes", value: 60 * 15 },
              { label: "30 Minutes", value: 60 * 30 },
              { label: "Hourly", value: 60 * 60 },
            ];
          } else {
            this.chart.granularity = [{ label: "Hourly", value: 60 * 60 }];
          }
          if (!set_default_granularity) {
            this.chart.selectedGranularity = 60 * 60;
          }
        } else if (this.chart.selectedDuration == 1) {
          this.chart.granularity = [
            { label: "Hourly", value: 60 * 60 },
            { label: "Daily", value: 60 * 60 * 24 },
          ];

          if (!set_default_granularity) {
            this.chart.selectedGranularity = 60 * 60 * 24;
          }
        } else if (this.chart.selectedDuration >= 2) {
          this.chart.granularity = [
            { label: "Daily", value: 60 * 60 * 24 },
            { label: "Weekly", value: 60 * 60 * 24 * 7 },
          ];
          if (!set_default_granularity) {
            this.chart.selectedGranularity = 60 * 60 * 24;
          }
        }
      }
    },
    getDateDiffInDays(startDate: string, endDate: string) {
      const diffTime = Math.abs(
        new Date(endDate).getTime() - new Date(startDate).getTime()
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    generateRandomColor(num: number) {
      const colors = [
        "Blue",
        "Green",
        "Brown",
        "Purple",
        "Navy blue",
        "Pea green",
        "Gray",
        "Orange",
        "Maroon",
        "Coral",
        "Fuchsia",
        "Crimson",
        "Khaki",
        "Hot pink",
        "Magenta",
        "Cyan",
      ];
      return colors[num % 16];
    },
    convertDate(date: Date) {
      const yyyy = date.getFullYear().toString();
      const mm = (date.getMonth() + 1).toString();
      const dd = date.getDate().toString();

      const mmChars = mm.split("");
      const ddChars = dd.split("");

      return (
        yyyy +
        "-" +
        (mmChars[1] ? mm : "0" + mmChars[0]) +
        "-" +
        (ddChars[1] ? dd : "0" + ddChars[0])
      );
    },
    refreshPage() {
      // if (this.selectedChartType === "occupancy") {
      //   this.chart.startDate = this.todaysDate;
      //   this.chart.endDate = this.todaysDate;
      // } else {
      //   this.chart.startDate = this.yesterdaysDate;
      //   this.chart.endDate = this.yesterdaysDate;
      // }

      // this.chart.startTime = "00:00";
      // this.chart.endTime = "23:59";
      // this.chart.selectedArea = -1;
      // this.chart.selectedGranularity = 60 * 30;
      // this.chart.selectedDuration = 0;
      // this.chart.selectedSpotPermitType = null;
      // this.chart.ignoreParkingSessionsUnder = 60;
      // this.chart.ignoreUnmappedSpots = false;
      // this.occupanciesData = null;
      // this.setChartDates();
      this.fetchData();
    },
    setTimeIfSameDay() {
      if (this.chart.startDate === this.chart.endDate) {
        this.chart.startTime = "00:00";
        this.chart.endTime = "23:59";
        if (this.selectedChartType === "occupancy") {
          this.chart.granularity = [
            { label: "5 Minutes", value: 60 * 5 },
            { label: "15 Minutes", value: 60 * 15 },
            { label: "30 Minutes", value: 60 * 30 },
            { label: "Hourly", value: 60 * 60 },
          ];
        } else {
          this.chart.granularity = [{ label: "Hourly", value: 60 * 60 }];
        }
      }
      this.chart.selectedGranularity = this.chart.granularity[0].value;
      if (this.chart.selectedDuration == 0) {
        this.chart.selectedGranularity = 60 * 60;
      } else if (this.chart.selectedDuration == 1) {
        this.chart.selectedGranularity = 60 * 60 * 24;
      }
    },
    // convert 24 hour time to 12 hour time format
    timeConvert(time: string) {
      return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
    },

    exportToCsv(filename: string, rows: Array<Array<string | number>>) {
      let csvContent = "data:text/csv;charset=utf-8,";

      rows.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", filename);
      document.body.appendChild(link);

      link.click();
    },
    async exportDataToXLSX() {
      this.isExportingData = true;
      try {
        this.$dialog.message.info("Exporting Raw Chart data ...", {
          position: "top-right",
          timeout: 3000,
        });
        const downloadSuccessful = await api.exportDwellTimeChartData(
          this.lotId,
          this.parkingLotData.name,
          this.chart.selectedArea,
          `${this.chart.startDate} ${this.chart.startTime}`,
          `${this.chart.endDate} ${this.chart.endTime}`,
          this.chart.selectedGranularity,
          this.chart.selectedSpotPermitType
        );
        if (downloadSuccessful) {
          this.$dialog.message.info("Exported Dwell time data successfully.", {
            position: "top-right",
            timeout: 3000,
          });
        } else {
          this.$dialog.message.error(
            "Error, unable to export Dwell time data. Please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, unable to export Dwell time data at the moment. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        this.isExportingData = false;
      }
    },
    exportChartData() {
      if (this.selectedChartType === "dwell_time") {
        this.exportDataToXLSX();
        return;
      }
      this.isExportingData = true;

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      if (this.selectedChartType === "occupancy") {
        if (this.occupanciesData) {
          let fileName = `${
            this.parkingLotData.name
          } Occupancy - ${dayjs().format("DD-MM-YYYY h:mm a")}`;

          let all_zone_lot_data: Array<Array<any>> = [];
          const timestamps = [
            ...new Set(this.occupanciesData.map((o) => o.ts)),
          ];
          let idx = 0;
          for (let ts of timestamps) {
            const occupancies = this.occupanciesData.filter((o) => o.ts === ts);
            if (occupancies.length > 0) {
              let occ_count = 0;
              let tot_count = 0;
              let occ_idx = 0;
              for (let occupancy of occupancies) {
                const d: Date = new Date(occupancy.local_time);
                let zone_name = "";
                const zone = this.parkingLotData.parking_zones.find(
                  (a) => a.id === occupancy.parking_zone_id
                );
                if (zone) {
                  zone_name = zone.name ? zone.name : `Zone-${zone.id}`;
                }

                const format_day = dayjs(d).format("DD-MM-YYYY h:mm a");

                all_zone_lot_data.push([
                  occ_idx != 0 ? "" : idx + 1,
                  occ_idx != 0 ? "" : format_day,
                  occ_idx != 0 ? "" : days[d.getDay()],
                  zone_name,
                  occupancy.tot_count,
                  occupancy.occ_count,
                  `${Math.round(
                    (occupancy.occ_count / occupancy.tot_count) * 100
                  )}%`,
                ]);

                occ_count += occupancy.occ_count;
                tot_count += occupancy.tot_count;
                occ_idx += 1;
              }

              all_zone_lot_data.push([
                "",
                "",
                "",
                "Total Lot",
                tot_count,
                occ_count,
                `${Math.round((occ_count / tot_count) * 100)}%`,
              ]);
              all_zone_lot_data.push(["", "", "", "", "", "", "", ""]);
              idx += 1;
            }
          }

          let occupancyExportData = [
            [
              "Serial Number",
              "Date and Time",
              "Day",
              "Zone Name",
              "Total Spots",
              "Occupied Spots",
              "Occupancy Percentage",
            ],
            ...all_zone_lot_data,
          ];

          this.exportToCsv(`${fileName}.csv`, occupancyExportData);

          this.$dialog.message.info(
            "Exported Occupancies data to a Spreadsheet successfully.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        } else {
          this.$dialog.message.error(
            "Error exporting Occupancy data. Please try again.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      } else if (this.selectedChartType === "dwell_time") {
        if (this.dwellTimeData) {
          let fileName = `${
            this.parkingLotData.name
          } Dwell Time - ${dayjs().format("DD-MM-YYYY h:mm a")}`;

          let all_data: Array<Array<any>> = [];
          const timestamps = [...new Set(this.dwellTimeData.map((o) => o.ts))];
          let idx = 0;
          let area_name = this.parkingLotData.name;
          for (let ts of timestamps) {
            const dwell_time_data = this.dwellTimeData.filter(
              (o) => o.ts === ts
            );
            if (dwell_time_data.length > 0) {
              if (this.chart.selectedArea !== -1) {
                const zone = this.parkingLotData.parking_zones.find(
                  (a) => a.id === this.chart.selectedArea
                );
                if (zone) {
                  area_name = zone.name ? zone.name : `Zone-${zone.id}`;
                }
              }
              for (let data of dwell_time_data) {
                const d: Date = new Date(data.local_time);
                const format_day = dayjs(d).format("DD-MM-YYYY h:mm a");

                const total_parking_sessions =
                  /* dwell_time.time_0 +*/ data.time_1to15 +
                  data.time_15to60 +
                  data.time_1hour +
                  data.time_2hour +
                  data.time_3hour +
                  data.time_4plushour;

                all_data.push([
                  idx + 1,
                  format_day,
                  days[d.getDay()],
                  "Number of Parking Sessions: ",
                  // data.time_0,
                  data.time_1to15,
                  data.time_15to60,
                  data.time_1hour,
                  data.time_2hour,
                  data.time_3hour,
                  data.time_4plushour,
                ]);
                all_data.push([
                  "",
                  "",
                  "",
                  "% of Parking Sessions: ",
                  // Math.round(
                  //   ((data.time_0 / total_parking_sessions) * 100 +
                  //     Number.EPSILON) *
                  //     100
                  // ) / 100,
                  total_parking_sessions === 0
                    ? 0
                    : Math.round(
                        ((data.time_1to15 / total_parking_sessions) * 100 +
                          Number.EPSILON) *
                          100
                      ) / 100,
                  total_parking_sessions === 0
                    ? 0
                    : Math.round(
                        ((data.time_15to60 / total_parking_sessions) * 100 +
                          Number.EPSILON) *
                          100
                      ) / 100,
                  total_parking_sessions === 0
                    ? 0
                    : Math.round(
                        ((data.time_1hour / total_parking_sessions) * 100 +
                          Number.EPSILON) *
                          100
                      ) / 100,
                  total_parking_sessions === 0
                    ? 0
                    : Math.round(
                        ((data.time_2hour / total_parking_sessions) * 100 +
                          Number.EPSILON) *
                          100
                      ) / 100,
                  total_parking_sessions === 0
                    ? 0
                    : Math.round(
                        ((data.time_3hour / total_parking_sessions) * 100 +
                          Number.EPSILON) *
                          100
                      ) / 100,
                  total_parking_sessions === 0
                    ? 0
                    : Math.round(
                        ((data.time_4plushour / total_parking_sessions) * 100 +
                          Number.EPSILON) *
                          100
                      ) / 100,
                ]);
              }
              all_data.push(["", "", "", "", "", "", "", "", "", "", ""]);
              idx += 1;
            }
          }

          let exportData = [
            [
              "Serial Number",
              "Date and Time",
              "Day",
              `${area_name} data`,
              // "< 0",
              "<15 min",
              "15-60 min",
              ">1 hour",
              ">2 hour",
              ">3 hour",
              ">4 hour",
            ],
            ...all_data,
          ];

          this.exportToCsv(`${fileName}.csv`, exportData);

          this.$dialog.message.info(
            "Exported Dwell time data to a Spreadsheet successfully.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        } else {
          this.$dialog.message.error(
            "Error exporting Dwell time data. Please try again.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      } else if (this.selectedChartType === "turnover") {
        if (this.parkingTurnoverData) {
          let fileName = `${
            this.parkingLotData.name
          } Parking Turnover - ${dayjs().format("DD-MM-YYYY h:mm a")}`;

          let all_data: Array<Array<any>> = [];
          const timestamps = [
            ...new Set(this.parkingTurnoverData.map((o) => o.ts)),
          ];
          let idx = 0;
          let area_name = this.parkingLotData.name;
          let total_spots = 0;
          for (let ts of timestamps) {
            const turnover_data = this.parkingTurnoverData.filter(
              (o) => o.ts === ts
            );
            if (turnover_data.length > 0) {
              if (this.chart.selectedArea !== -1) {
                const zone = this.parkingLotData.parking_zones.find(
                  (a) => a.id === this.chart.selectedArea
                );
                if (zone) {
                  area_name = zone.name ? zone.name : `Zone-${zone.id}`;
                }
              }

              for (let data of turnover_data) {
                const d: Date = new Date(data.local_time);
                const format_day = dayjs(d).format("DD-MM-YYYY h:mm a");
                total_spots = data.number_of_spots;

                all_data.push([
                  idx + 1,
                  format_day,
                  days[d.getDay()],
                  data.parking_sessions,
                  this.roundValue(data.turnover),
                ]);
              }
              idx += 1;
            }
          }

          let exportData = [
            ["Total Spots", total_spots],
            ["", "", "", "", ""],
            [
              "Serial Number",
              "Date and Time",
              "Day",
              "Parking Sessions",
              "Parking Turnover",
            ],
            ...all_data,
          ];

          this.exportToCsv(`${fileName}.csv`, exportData);

          this.$dialog.message.info(
            "Exported Parking Turnover data to a Spreadsheet successfully.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        } else {
          this.$dialog.message.error(
            "Error exporting  Parking Turnover data. Please try again.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      }

      this.isExportingData = false;
    },
    getLocalDate(d: Date) {
      return dayjs(d).format("YYYY-MM-DD");
    },
    roundValue(value: number) {
      const stringValue = String(value);
      const decimalIndex = stringValue.indexOf(".");
      let zeroCount = 0;
      if (decimalIndex !== -1) {
        for (let i = decimalIndex + 1; i < stringValue.length; i++) {
          if (stringValue[i] === "0") {
            zeroCount++;
          } else {
            break;
          }
        }
      }
      if (zeroCount >= 2) {
        return +value.toFixed(zeroCount + 1);
      } else {
        return Math.round((value + Number.EPSILON) * 100) / 100;
      }
    },
  },
  watch: {
    "chart.startDate"() {
      this.setTimeIfSameDay();
    },
    "chart.endDate"() {
      this.setTimeIfSameDay();
    },
    occupancyReportType() {
      this.selectedChartType = this.occupancyReportType;
      this.updateChartData();
    },
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn", "hasAccessLevelDashboardMonitoring"]),
    singleDaySelected() {
      if (this.chart.startDate === this.chart.endDate) {
        return true;
      }
      return false;
    },
    todaysDate() {
      return dayjs().format("YYYY-MM-DD");
    },
    yesterdaysDate() {
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    },
    actualValuesText() {
      if (this.selectedChartType === "occupancy") {
        return "Show Occupied Spots Count";
      } else if (this.selectedChartType === "dwell_time") {
        return "Show Number of Parking Sessions";
      } else if (this.selectedChartType === "turnover") {
        return "Show Number of Parking Sessions";
      }
      return "Show Occupied Spots Count";
    },
  },
});
