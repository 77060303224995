import axios from "axios";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import validationUtils from "@/libs/validationUtils";

import compact from "@/assets/icons/compact.svg";
import ev from "@/assets/icons/ev.svg";
import { mdiTagOutline } from "@mdi/js";

// TODO
Vue.prototype.$openAdminNextDashboard = function (is_admin: false) {
  if (is_admin) {
    window.open(`${process.env.VUE_APP_3_BASE_URL_PATH}/dashboard`, "_self");
  } else {
    window.open(
      `${new URL(process.env.VUE_APP_API_URL).origin}/admin/home`,
      "_self"
    );
  }
};
Vue.prototype.$openAdminNextLink = function (link: string) {
  window.open(`${process.env.VUE_APP_3_BASE_URL_PATH}/${link}`, "_self");
};
Vue.prototype.$openAdminNextLinkNewTab = function (link: string) {
  window.open(`${process.env.VUE_APP_3_BASE_URL_PATH}/${link}`, "_blank");
};
Vue.prototype.$getCustomSvgPath = function (icon_name: string) {
  if (icon_name === "sg-compact") {
    return compact;
  } else if (icon_name === "sg-ev") {
    return ev;
  }
  return mdiTagOutline;
};

Vue.use(VueViewer);

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});

Vue.mixin(validationUtils);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
// Add token in auth header for every network request
// Also, add user type in case switch view is activated for superadmins.
axios.interceptors.request.use((config) => {
  const token = store.getters["user/token"];
  const userType = store.getters["user/currentUserType"];
  config.headers.Authorization = `Bearer ${token}`;
  config.headers["x-sg-org-user-switch-view"] = userType;

  // check if url token is present, if so, add it to the header
  const url_token = localStorage.getItem("url_token");
  if (url_token) {
    config.headers["UrlToken"] = url_token;
  }

  return config;
});
// Logout the current user if auth token is invalid/expired
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.commit("user/logout");
    }
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

Vue.filter("capitalizeText", function (text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
});
Vue.filter("formatLocalTimestamp", function (timestamp: string) {
  function getElapsedTime(startTime: Date) {
    const endTime = new Date();
    let timeDiff = Math.abs(endTime.getTime() - startTime.getTime());
    timeDiff = timeDiff / 1000;
    const seconds = Math.floor(timeDiff % 60); //ignoring uncompconste seconds (floor)
    const secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
    timeDiff = Math.floor(timeDiff / 60);
    const minutes = timeDiff % 60; //no need to floor possible incompconste minutes, becase they've been handled as seconds
    const minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
    timeDiff = Math.floor(timeDiff / 60);
    const hours = timeDiff % 24; //no need to floor possible incompconste hours, becase they've been handled as seconds
    timeDiff = Math.floor(timeDiff / 24);
    const days = timeDiff;
    const totalHours = hours + days * 24; // add days to hours
    const totalHoursAsString =
      totalHours < 10 ? "0" + totalHours : totalHours + "";

    if (days > 0) {
      if (days > 30 && days / 30 > 1) {
        if (days / 30 > 12) {
          return `${Math.round(days / 30 / 12)} years`;
        }
        return `${Math.round(days / 30)} ${
          days / 30 > 1 ? "months" : "month"
        } ${days % 30} ${days % 30 > 1 ? "days" : "day"}`;
      }
      return `${days} ${days > 1 ? "days" : "day"}`;
    } else if (totalHoursAsString !== "00") {
      return `${totalHours} ${totalHours > 1 ? "hours" : "hour"}`;
    } else if (minutesAsString !== "00") {
      return `${minutes} ${minutes > 1 ? "minutes" : "minute"}`;
    } else if (secondsAsString !== "00") {
      return `${seconds} ${seconds > 1 ? "seconds" : "second"}`;
    }
    return "0 seconds";
  }
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return `${new Date(timestamp + "+00:00").toLocaleDateString(
    "en-US",
    options
  )} (${getElapsedTime(new Date(timestamp + "+00:00"))})`;
});

Vue.filter("formatLocalDate", function (timestamp: string) {
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return `${new Date(timestamp + "+00:00").toLocaleDateString(
    "en-US",
    options
  )}`;
});

Vue.filter("formatLocalTimestampDayjs", function (timestamp: string) {
  const timestampObj = dayjs(timestamp).utc(true).local(); // Convert UTC to local
  const timestampStr = timestampObj.format("ddd, MMM D, h:mm A");
  const fromNowStr = timestampObj.fromNow(true);
  return `${timestampStr} (${fromNowStr})`;
});

Vue.filter("formatLocalTimestampSecondsDayjs", function (timestamp: string) {
  const timestampObj = dayjs(timestamp).utc(true).local(); // Convert UTC to local
  const timestampStr = timestampObj.format("ddd, MMM D, h:mm:ss A");
  const fromNowStr = timestampObj.fromNow(true);
  return `${timestampStr} (${fromNowStr})`;
});

Vue.filter("capitalize", function (text_words: string) {
  const capitalized: string[] = [];
  text_words.split(" ").forEach((word) => {
    capitalized.push(
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
  });
  return capitalized.join(" ");
});

Vue.filter("truncateLines", function (value: string, maxLength = 30) {
  if (value.length > maxLength) {
    return value.slice(0, maxLength) + "...";
  }
  return value;
});

Vue.filter("formatJsonObject", function (json_data: any) {
  if (!json_data) {
    return "Not Detected";
  }
  const obj_keys = Object.keys(json_data);
  let final_str = "<ul>";
  obj_keys.forEach(function (key) {
    final_str += `<li>${key}: ${json_data[key]}</li>`;
  });
  final_str += "</ul>";
  return final_str;
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
